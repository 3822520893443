import { TypeGameEnum } from "models/EnumModeGame";
import { EnumGame, EnumPath } from "models/EnumPath";
import { CurrentSession } from "models/Session";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetMeQuery } from "store/features/authSlice";
import { useUpdateSessionMutation } from "store/features/sessionSlice";
import { changeStatusSession, currentSessionSelector } from "store/slices/currentSessionSlice";
import { modeGameSelector, setModeGame } from "store/slices/modeGameSlice";
import { useGamePath } from "./useGamePath";

/**
 * useEditSessionGame Custom Hook: edit the current session.
 *
 * @param {boolean} isReverseGame - Indicates whether the session is in reverse mode.
 * @param {boolean} completed - Indicates whether the session is completed.
 */

const useEditSessionGame = () => {
  const dispatch = useDispatch();
  const modeGame = useSelector(modeGameSelector);
  const { id, userId, gameName, gameInfo } = useSelector(currentSessionSelector);
  const navigate = useNavigate();
  const { currentGameMode } = useGamePath();
  const updatedScore = useCallback(
    (gameName: string, completed: boolean) => {
      return gameName && gameName === EnumGame.SLOTH_GAME ?
          {
            firstPart: completed ? undefined : gameInfo.score.firstPart,
            secondPart: completed ? gameInfo.score.secondPart : undefined,
          }
        : {
            forwardScore: completed ? undefined : gameInfo.score.forwardScore,
            reverseScore: completed ? gameInfo.score.reverseScore : undefined,
          };
    },
    [gameInfo.score]
  );

  const [updateSession, { isUninitialized }] = useUpdateSessionMutation();
  const { refetch, isSuccess } = useGetMeQuery("");

  const updateCurrentSession = useCallback(
    async ({ isReverseGame, completed }: Record<string, boolean>) => {
      if (isUninitialized) {
        try {
          const data = {
            id,
            userId,
            gameName,
            gameInfo: {
              ...gameInfo.completedAt,
              isReverseGame,
              completed: completed,
              score: updatedScore(gameName, completed),
            },
          } as CurrentSession;

          const update = (await updateSession(data)) as { data: { session: CurrentSession } };
          const session = update.data.session;
          dispatch(
            changeStatusSession({
              isReverseGame,
              completed: completed,
              nBack: session.gameInfo.nBack,
            })
          );
        } catch (error) {
          console.error(error);
          // if you do not interact with the game, play the initial demo again
          dispatch(
            setModeGame({
              mode: modeGame.mode,
              type: TypeGameEnum.START_MODAL_DEMO,
            })
          );

          const path =
            (
              gameName &&
              [EnumPath.PARROT_GAME, EnumGame.TOUCAN_GAME, EnumGame.SLOTH_GAME].includes(
                gameName
              )
            ) ?
              `/${EnumPath.GAMES}/${gameName}/${currentGameMode}`
            : `/${EnumPath.DASHBOARD}`;
          navigate(path);
        }
        if (isSuccess && completed) await refetch();
      }
    },
    [
      isUninitialized,
      isSuccess,
      refetch,
      id,
      userId,
      gameName,
      gameInfo.completedAt,
      updatedScore,
      updateSession,
      dispatch,
      modeGame.mode,
      navigate,
      currentGameMode,
    ]
  );

  return {
    updateCurrentSession,
  };
};

export default useEditSessionGame;
