import React from "react";
import { Modal } from "components/Modal/Modal";
import { Square } from "components/Square/Square";
import useGetCurrentUser from "hook/useGetCurrentUser";
import { useToucanGame } from "hook/useToucanGame";
import { TypeGameEnum } from "models/EnumModeGame";
import { EnumGame, EnumPath, EnumPlayMode } from "models/EnumPath";
import { SceneEnum } from "models/EnumScene";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  modeGameSelector,
  setGame,
  setModeGame,
  setTimerPaused,
} from "store/slices/modeGameSlice";
import { EnumSecondGameSquare } from "models/EnumSecondGameSquare";
import detectiveListen from "assets/img/common/Detective/listening-and-memorising.svg";
import detectiveSpeak from "assets/img/common/Detective/detective-speak-reverse.svg";
import getClasses from "utils/getClasses";
import { useEffectAudio } from "hook/useEffectAudio";
import { DemoToucanGame } from "components/DemoGame/DemoToucanGame/DemoToucanGame";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";
import { useGamePath } from "hook/useGamePath";

export const ToucanGame = React.memo(() => {
  const dispatch = useDispatch();
  const modeGame = useSelector(modeGameSelector);
  const { currentUser } = useGetCurrentUser();
  const { timerPaused } = useSelector(modeGameSelector);
  const { currentScene } = useSelector(modeGameSelector);
  const [isModalDemoOpen, setIsModalDemoOpen] = useState(true);
  const [isModalGameOpen, setIsModalGameOpen] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const { wichGameModeIs } = useGamePath(EnumGame.TOUCAN_GAME);
  const isReverse = wichGameModeIs(EnumPlayMode.PLAY_REVERSE);
  const {
    matrix,
    sequentialWords,
    sequentialAudio,
    checkTutorial,
    setsequentialWordsUser,
    setIsFlashing,
    isFlashing,
    sequentialWordsUser,
  } = useToucanGame({
    reverse: isReverse,
  });

  // const titleWords = "Le parole pronunciate sono:";
  // const yourTurn = "Tocca a te: ";

  const {
    volumeVoice,
    toggleCurrentMusic,
    isPaused: isStopSpeech,
    setIsPaused: setIsStopSpeech,
  } = useEffectAudio();

  useEffect(() => {
    isModalDemoOpen &&
      dispatch(
        setModeGame({
          mode: modeGame.mode,
          type: TypeGameEnum.START_MODAL_DEMO,
        })
      );
  }, [dispatch, isModalDemoOpen, modeGame.mode]);

  const closeModal = useCallback(
    (
      type:
        | TypeGameEnum.TRAINING_TUTORIAL
        | TypeGameEnum.PLAYING_TUTORIAL
        | TypeGameEnum.SPEECH_GAME
        | TypeGameEnum.PLAY_GAME,
      repeat?: boolean
    ) => {
      setIsFadingOut(true);
      if (repeat) {
        setIsModalDemoOpen(true);
        setIsModalGameOpen(false);
      }
      setTimeout(() => {
        type === TypeGameEnum.TRAINING_TUTORIAL && setIsModalDemoOpen(false);
        type === TypeGameEnum.SPEECH_GAME && setIsModalGameOpen(false);
        const mode = modeGame.mode;

        dispatch(
          setModeGame({
            type: repeat ? TypeGameEnum.SPEECH_GAME : type, // Update the mode
            mode, // Preserve the current mode
          })
        );

        setIsFadingOut(false);
      }, 750);
    },
    [dispatch, modeGame.mode]
  );

  useEffect(() => {
    dispatch(setGame(EnumPath.TOUCAN_GAME));
  }, [dispatch]);

  useEffect(() => {
    toggleCurrentMusic("music", "demo1");
  }, [toggleCurrentMusic]);

  useEffect(() => {
    modeGame.type === TypeGameEnum.START_MODAL_GAME && setIsModalGameOpen(true);
  }, [currentScene, dispatch, modeGame.type]);

  useEffect(() => {
    if (isReverse) {
      setIsModalDemoOpen(true);
      setIsModalGameOpen(false);
    }
  }, [isReverse, modeGame.mode]);

  useEffect(() => {
    sequentialWords.length === sequentialWordsUser.length &&
      modeGame.type === TypeGameEnum.PLAY_GAME &&
      closeModal(TypeGameEnum.SPEECH_GAME);
  }, [closeModal, modeGame.type, sequentialWords.length, sequentialWordsUser.length]);

  useEffect(() => {
    isStopSpeech && dispatch(setTimerPaused(false));
    isStopSpeech &&
      modeGame.type === TypeGameEnum.SPEECH_GAME &&
      closeModal(TypeGameEnum.PLAY_GAME);
    isStopSpeech &&
      modeGame.type === TypeGameEnum.SPEECH_GAME_TUTORIAL &&
      closeModal(TypeGameEnum.PLAYING_TUTORIAL);
  }, [closeModal, dispatch, isStopSpeech, modeGame.type, volumeVoice]);

  useEffect(() => {
    matrix && sequentialWords && setIsStopSpeech(false);
  }, [sequentialWords, matrix, setIsStopSpeech]);

  //When starting the game, the timer is paused
  useEffect(() => {
    if (modeGame.type === TypeGameEnum.SPEECH_GAME && sequentialWords.length > 0) {
      dispatch(setTimerPaused(true));
      setIsStopSpeech(false);
    }
  }, [modeGame.type, sequentialWords.length, dispatch, setIsStopSpeech]);

  const renderSpeech = useMemo(
    //TODO: si verifica un BUG legato alla riproduzione dell'audio narrator-03:
    //nel componente ContainerGame abbiamo delle condizioni per le quali
    //vengono riprodotti i due file audio del narratore, in particolare, dopo
    //il Training Tutorial, se il type del modeGame === Playing Tutorial viene riprodotto
    // il narrator-03. Subito dopo modeGame.type è === Speech Game, quindi dovrebbe
    //rientrare tra le condizioni per cui dovrebbe partire il sintetizzatore
    //(line 157-158) ma ciò non accade la prima volta, per le successive invece si.
    () =>
      (
        modeGame.type !== TypeGameEnum.TRAINING_TUTORIAL &&
        (modeGame.type === TypeGameEnum.SPEECH_GAME_TUTORIAL ||
          modeGame.type === TypeGameEnum.SPEECH_GAME) &&
        sequentialWords &&
        sequentialAudio &&
        !isStopSpeech
      ) ?
        // TODO: Quando avremo gli audio giusti, il componente SpeechAudio avrà la prop audioFiles={[titleWords, ...sequentialAudio, yourTurn]}
        <>
          {/* <SpeechSynthesis
            notVisible
            texts={[titleWords]}
            start={sequentialWords.length > 0}
            setFinished={() => setIsStopSpeech(true)}
          /> */}

          <SpeechAudio
            notVisible
            audioFiles={[...sequentialAudio]}
            start={Boolean(!isStopSpeech)}
            setFinished={() => setIsStopSpeech(true)}
          />
          {/* <SpeechSynthesis
            notVisible
            texts={[yourTurn]}
            start={sequentialWords.length > 0}
            setFinished={() => setIsStopSpeech(true)}
          /> */}
        </>
      : null,
    [modeGame.type, sequentialWords, sequentialAudio, isStopSpeech, setIsStopSpeech]
  );

  const textModal = () =>
    currentScene.includes(SceneEnum.SPINOFF_SCENE) ? "Giochiamo!"
    : isModalDemoOpen ? "Prima di procedere, facciamo una prova"
    : `Ok, sei pront${currentUser?.gender === "MALE" ? "o" : "a"}. Giochiamo!`;

  const audioModal =
    currentScene.includes(SceneEnum.SPINOFF_SCENE) ? "Giochiamo!"
    : isModalDemoOpen ? "general/detective-felix-before-do-test"
    : `general/detective-felix-ok-u-ready-${
        currentUser && currentUser.gender === "MALE" ? "M" : "F"
      }`;

  const renderAudio = useMemo(
    () => (
      <SpeechAudio
        notVisible
        audioFiles={[`${audioModal}`]}
        start={isModalDemoOpen || isModalGameOpen}
      />
    ),
    [audioModal, isModalDemoOpen, isModalGameOpen]
  );

  return (
    <>
      <Modal
        isOpen={isModalDemoOpen || isModalGameOpen}
        onClose={() => closeModal(TypeGameEnum.TRAINING_TUTORIAL)}
      >
        <div className={`${isFadingOut ? "fade-out-image" : "fade-in-image"}`}>
          <DemoToucanGame
            data-cy="demo-modal"
            type="demo"
            action={
              isModalDemoOpen ?
                () =>
                  closeModal(
                    TypeGameEnum.TRAINING_TUTORIAL,
                    currentScene.includes(SceneEnum.SPINOFF_SCENE)
                  )
              : () => closeModal(TypeGameEnum.SPEECH_GAME)
            }
            startTutorial={() => closeModal(TypeGameEnum.TRAINING_TUTORIAL)}
            reverse={isReverse}
            title={textModal()}
          />
        </div>
      </Modal>

      {!isModalDemoOpen && !isModalGameOpen && (
        <div className="container-game toucan-game">
          {renderSpeech}
          <div className="container-grid grid-toucan-game">
            <div
              className={`detective container-image-scene fade-in-image ${getClasses({
                "fade-out-image":
                  (isStopSpeech &&
                    (modeGame.type === TypeGameEnum.SPEECH_GAME ||
                      modeGame.type === TypeGameEnum.SPEECH_GAME_TUTORIAL)) ||
                  (!isStopSpeech && modeGame.type === TypeGameEnum.PLAY_GAME),
              })}`}
            >
              <img
                className="detective-img"
                src={
                  (
                    modeGame.type === TypeGameEnum.PLAYING_TUTORIAL ||
                    modeGame.type === TypeGameEnum.PLAY_GAME
                  ) ?
                    detectiveListen
                  : detectiveSpeak
                }
                alt="detective"
              />
            </div>
            <div className="container-col-grid-toucan">
              {matrix?.map((col, j) => (
                <div className="col-grid-toucan-game" key={j}>
                  {col &&
                    col.map((_, i) => {
                      return (
                        <Square
                          key={i}
                          element={matrix[j][i]}
                          withIMG
                          clickable={
                            modeGame.type === TypeGameEnum.PLAY_GAME ||
                            modeGame.type === TypeGameEnum.PLAYING_TUTORIAL
                          }
                          blockAnimation={isFlashing}
                          action={(element) => {
                            if (!timerPaused) {
                              if (modeGame.type !== TypeGameEnum.SPEECH_GAME) {
                                setIsFlashing(false);
                              }
                              ((element &&
                                modeGame.type === TypeGameEnum.PLAYING_TUTORIAL &&
                                checkTutorial(element as keyof typeof EnumSecondGameSquare)) ||
                                (element &&
                                  modeGame.type === TypeGameEnum.PLAY_GAME &&
                                  !timerPaused)) &&
                                setsequentialWordsUser((prev) => [
                                  ...prev,
                                  EnumSecondGameSquare[
                                    element as keyof typeof EnumSecondGameSquare
                                  ],
                                ]);
                            }
                          }}
                          animation={
                            modeGame.type === TypeGameEnum.SPEECH_GAME ||
                            modeGame.type === TypeGameEnum.SPEECH_GAME_TUTORIAL ||
                            timerPaused
                          }
                        />
                      );
                    })}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {renderAudio}
    </>
  );
});
