import React, { FC } from "react";
import { WrapperInterludeFC } from "models/WrapperInterludeFC";
import "./WrapperInterlude.scss";
import { RoundButton } from "components/RoundButton/RoundButton";
import { GenericButton } from "components/GenericButton/GenericButton";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeCurrentSession } from "store/slices/currentSessionSlice";
import { ModeGameEnum, TypeGameEnum } from "models/EnumModeGame";
import { EnumPath } from "models/EnumPath";
import { setGame, setModeGame } from "store/slices/modeGameSlice";
import getClasses from "utils/getClasses";

/**
 * WrapperInterlude Component
 *
 * This component serves as a container for interlude content. It includes buttons for navigation.
 *
 * @param {ReactElement} children - The child element (interlude content) to be rendered within this wrapper.
 * @param {Function} action - The function to be executed when the "avanti" button is clicked.
 * @param {boolean} isFadingOut - Indicates whether the content is fading out.
 * @param {string} pathToButton - indicates which path to send to the skip intro button.
 * @param {number} numberMultiPage - Indicates number of the scene inside component
 * @returns {JSX.Element} - The JSX element representing the component.
 */

export const WrapperInterlude: FC<WrapperInterludeFC> = React.memo(
  ({
    hideBackButton = false,
    backButtonHandler = () => {},
    nextButtonHandler = () => {},
    actions,
    children,
    scene,
  }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { close, skip, back, next } = actions;

    const goBackHome = () => {
      dispatch(
        setModeGame({
          type: TypeGameEnum.START_MODAL_DEMO,
          mode: ModeGameEnum.FORWARD_GAME,
        })
      );
      dispatch(removeCurrentSession());
      navigate(`/${EnumPath.DASHBOARD}`);
      dispatch(setGame(""));
    };

    return (
      <div data-cy="wrapper-interlude" className="container-interlude fade-in-image">
        <div
          data-cy="wrapper-interlude-top-actions"
          className={`container-interlude-top ${getClasses({
            "container-skip": skip && !close,
          })}`}
        >
          {actions && close && <RoundButton type="close" action={goBackHome} />}
          {skip?.label && (
            <GenericButton
              type="button"
              text={(skip.label as string) || ""}
              color="theme-blue"
              action={() => navigate(skip.path || "")}
              size="large"
              scene={scene}
            />
          )}
        </div>
        <div data-cy="wrapper-interlude-children" className="container-interlude-center">
          {children}
        </div>
        <div data-cy="wrapper-interlude-bottom-actions" className="container-interlude-bottom">
          {back && !hideBackButton && <RoundButton type={"back"} action={backButtonHandler} />}
          {next?.label && (
            <GenericButton
              type="button"
              text={(next.label as string) || ""}
              color="theme-purple"
              action={nextButtonHandler}
              size="large"
            />
          )}
        </div>
      </div>
    );
  }
);
