import React, { useEffect, useState } from "react";
import "./App.scss";
import Router from "./router/Router";

window.onbeforeunload = function () {
  return "Sei sicuro di voler uscire?";
};

const App = () => {
  const [currentHeight, setCurrentHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setCurrentHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="App" style={{ height: currentHeight }}>
      <Router />
    </div>
  );
};

export default App;
