import React, { FC, useEffect, useState, useRef } from "react";
import "./Timer.scss";
import { TimerFc } from "models/TimerFc";
import { millisToMinutesAndSeconds } from "utils/millisecondsToMinuteAndSeconds";
import { useSelector } from "react-redux";
import { modeGameSelector } from "store/slices/modeGameSlice";

/**
 * Timer Component: Represents a countdown timer.
 *
 * @param {Object} props - The component's properties.
 * @param {number} props.time - The initial time value for the timer in milliseconds.
 * @param {function} props.timeUp - The function to be executed when the timer reaches zero.
 * @returns {JSX.Element} - The Timer component.
 */

export const Timer: FC<TimerFc> = ({ time, timeUp }) => {
  const [timer, setTimer] = useState(time);
  const [isPaused, setIsPaused] = useState(false);
  const { timerPaused } = useSelector(modeGameSelector);
  const hasTimeUpBeenCalled = useRef(false); // Use ref to avoid re-renders

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (!isPaused && timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prev) => (prev > 0 ? prev - 1000 : 0));
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [timer, isPaused]);

  useEffect(() => {
    setIsPaused(timerPaused || false);
  }, [timerPaused]);

  useEffect(() => {
    // Check if timer reaches 0 and timeUp hasn't been called yet
    if (timer === 0 && !hasTimeUpBeenCalled.current) {
      hasTimeUpBeenCalled.current = true; // Prevent multiple calls
      timeUp();
    }
  }, [timer, timeUp]);

  return (
    // Display the timer value in a formatted way.
    <span data-cy="test-timer" className="timer">
      {millisToMinutesAndSeconds(timer)}
    </span>
  );
};
