import alone from "assets/img/common/elements/alone-right.png";
import waitingFelix from "assets/img/common/Detective/concentration-zoom_1.svg";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { modeGameSelector } from "store/slices/modeGameSlice";
import getClasses from "utils/getClasses";
import "./SlothSpinoffSceneTwo.scss";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";

const SlothSpinoffSceneTwoData: SceneDataT = [
  {
    title: "Detective Felix",
    text: [
      "Bravissimo! Dobbiamo giocare solo un’altra volta e poi potremo restituire agli amici di Brady il loro albero.",
    ],
    audio: {
      felix: "sloth/detective-felix-08",
    },
    images: {
      felix: waitingFelix,
    },
  },
];

const SlothSpinoffSceneTwo: FC<Partial<ChapterSceneFC>> = ({ out, page = 1, action }) => {
  const { currentScene } = useSelector(modeGameSelector);
  const dataKey = currentScene.split("-")[0];
  const contents: SceneDataT = SlothSpinoffSceneTwoData;
  const {
    title,
    text,
    images: { felix },
  } = contents[0];
  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });

  const renderSpeech = useMemo(() => {
    return (
      page && (
        <SpeechAudio
          notVisible
          start={true}
          audioFiles={[`${contents[0].audio?.felix}`]}
          setFinished={() => setIsFinished(true)}
          endDelay={1000}
          startDelay={1000}
        />
      )
    );
  }, [contents, page, setIsFinished]);

  return (
    <div
      className={`container-spinoff-two fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      <div className={`content bg-${dataKey === "spinoffFirst" ? "first" : "second"}`}>
        <div className="bg-overlay" />
        <div className="page-container">
          <img src={alone} className="alone" alt="an alone behind felix" />
          <div className="balloon-container">
            <SimpleCard title={title} text={text} />
            {renderSpeech}
          </div>
          <img src={felix} className="felix-figure" alt="Felix is proud of you" />
        </div>
      </div>
    </div>
  );
};

export default SlothSpinoffSceneTwo;
