import { thirdGameStimuli, thirdGameStimuliTraining_N_Back } from "data/thirdGameStimuli";
import { TypeGameEnum } from "models/EnumModeGame";
import { EnumSlothGame } from "models/EnumSlothGame";
import { SlothScore } from "models/Session";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currentSessionSelector, setSlothScore } from "store/slices/currentSessionSlice";
import { modeGameSelector } from "store/slices/modeGameSlice";
import { useEffectAudio } from "./useEffectAudio";
import { useGamePath } from "./useGamePath";
import { EnumGame, EnumPlayMode } from "models/EnumPath";

type SlothGameType = keyof typeof EnumSlothGame;
export const useSlothGame = () => {
  const dispatch = useDispatch();

  const { gameInfo } = useSelector(currentSessionSelector);
  const { wichGameModeIs } = useGamePath(EnumGame.SLOTH_GAME);
  const isReversePlay = wichGameModeIs(EnumPlayMode.PLAY_REVERSE);
  const [sequence, setSequence] = useState<SlothGameType[]>([]);
  const [nBack, setNBack] = useState<number>(gameInfo.nBack || 1);
  const { activePlayWin } = useEffectAudio();
  const { type } = useSelector(modeGameSelector);
  const [score, setScore] = useState<SlothScore>({
    rightCouples: 0,
    wrongCouples: 0,
    skippedCouples: 0,
  });

  const generateRandomPair = useCallback((n: number): [SlothGameType, SlothGameType] => {
    const index = Math.floor(Math.random() * thirdGameStimuli[n].length);
    const stimulus = thirdGameStimuli[n][index];
    return [stimulus, stimulus];
  }, []);

  const generateRandomIndex = useCallback((max: number) => Math.floor(Math.random() * max), []);

  const generateArrayWithPairs = useCallback(
    (n: number) => {
      const array: (SlothGameType | SlothGameType[])[] = [];
      const totalPairs = type === TypeGameEnum.PLAYING_TUTORIAL ? 2 : 10;
      const totalCard = type === TypeGameEnum.PLAYING_TUTORIAL ? 10 : 40;
      const generatedPairs: SlothGameType[] = [];
      let index: number;
      if (type === TypeGameEnum.PLAY_GAME || type === TypeGameEnum.PLAYING_TUTORIAL) {
        Array.from({ length: totalCard }).forEach((_, i) => {
          if (i < totalPairs) {
            let pair: [SlothGameType, SlothGameType];
            do {
              pair = generateRandomPair(n);
            } while (generatedPairs.includes(pair[0]));
            generatedPairs.push(pair[0]);

            do {
              index = generateRandomIndex(totalCard - n);
            } while (
              index < 3 ||
              array[index] ||
              array[index + n] ||
              (index + n < array.length && array[index + n] === pair[0])
            );

            array[index] = [pair[0]];
            array[index + n] = [pair[1]];
          }

          if (!array[i]) {
            do {
              index = generateRandomIndex(thirdGameStimuli[n].length);
              index %= thirdGameStimuli[n].length;
              array[i] = thirdGameStimuli[n][index];
            } while (
              array[i] === array[i - 1] ||
              array[i] === array[i - 2] ||
              array[i] === array[i - 3] ||
              (Array.isArray(array[i - 1]) && array[i] === array[i - 1][0]) ||
              (Array.isArray(array[i + 1]) && array[i] === array[i + 1][0]) ||
              (Array.isArray(array[i - 2]) && array[i] === array[i - 2][0]) ||
              (Array.isArray(array[i + 2]) && array[i] === array[i + 2][0]) ||
              (Array.isArray(array[i - 3]) && array[i] === array[i - 3][0]) ||
              (Array.isArray(array[i + 3]) && array[i] === array[i + 3][0])
            );
          }
        });
        setSequence(array.flat());
      } else {
        setSequence(thirdGameStimuliTraining_N_Back[n]);
      }
    },
    [generateRandomIndex, generateRandomPair, type]
  );

  const checkUserChoiceAndScore = (index: number) => {
    if (sequence[index] === sequence[index - nBack]) {
      activePlayWin();
      setScore((prev) => ({ ...prev, rightCouples: prev.rightCouples + 1 }));
    } else {
      setScore((prev) => ({ ...prev, wrongCouples: prev.wrongCouples + 1 }));
    }
  };

  const skippedCard = () => {
    setScore((prev) => ({ ...prev, skippedCouples: prev.skippedCouples + 1 }));
  };

  useEffect(() => {
    score &&
      dispatch(
        setSlothScore({
          gamePart: `${isReversePlay ? "secondPart" : "firstPart"}`,
          score: score,
        })
      );
  }, [dispatch, isReversePlay, score]);

  useEffect(() => {
    generateArrayWithPairs(nBack);
  }, [generateArrayWithPairs, nBack]);
  return { sequence, nBack, setNBack, score, checkUserChoiceAndScore, setScore, skippedCard };
};
