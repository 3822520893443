import { EnumPath, EnumSceneFlow } from "models/EnumPath";
import { SceneEnum } from "models/EnumScene";
//Intro scene
import { GameIntro } from "pages/private/interludes/genericsInterludes/GameIntro/GameIntro";
//Parrot Forward Intro Scenes
import { ParrotFwdIntroSceneOne } from "pages/private/interludes/ParrotGame/Forward/ForwardOne/ParrotFwdIntroSceneOne";
import { ParrotFwdIntroStart } from "pages/private/interludes/ParrotGame/Forward/ForwardStarted/ParrotFwdIntroStart";
import { ParrotFwdIntroSceneTwo } from "pages/private/interludes/ParrotGame/Forward/ForwardTwo/ParrotFwdIntroSceneTwo";
//Parrot Reverse Intro Scenes
import { ParrotRevIntroSceneOne } from "pages/private/interludes/ParrotGame/Reverse/ReverseOne/ParrotRevIntroSceneOne";
import { ParrotRevIntroStart } from "pages/private/interludes/ParrotGame/Reverse/ReverseStarted/ParrotRevIntroStart";
import { ParrotRevIntroSceneTwo } from "pages/private/interludes/ParrotGame/Reverse/ReverseTwo/ParrotRevIntroSceneTwo";
//Parrot End Scenes
import { ParrotEndSceneFour } from "pages/private/interludes/ParrotGame/End/EndFour/ParrotEndSceneFour";
import { ParrotEndSceneOne } from "pages/private/interludes/ParrotGame/End/EndOne/ParrotEndSceneOne";
import { ParrotEndSceneThree } from "pages/private/interludes/ParrotGame/End/EndThree/ParrotEndSceneThree";
import { ParrotEndSceneTwo } from "pages/private/interludes/ParrotGame/End/EndTwo/ParrotEndSceneTwo";
//Parrot Spinoff scenes
import { ParrotSpinoffSceneFour } from "pages/private/interludes/ParrotGame/Spinoff/SpinoffFour/ParrotSpinoffSceneFour";
import { ParrotSpinoffSceneOne } from "pages/private/interludes/ParrotGame/Spinoff/SpinoffOne/ParrotSpinoffSceneOne";
import { ParrotSpinoffSceneThree } from "pages/private/interludes/ParrotGame/Spinoff/SpinoffThree/ParrotSpinoffSceneThree";
import { ParrotSpinoffSceneTwo } from "pages/private/interludes/ParrotGame/Spinoff/SpinoffTwo/ParrotSpinoffSceneTwo";
//Toucan Forward Intro scenes
import { ToucanFwdIntroSceneOne } from "pages/private/interludes/ToucanGame/Forward/ForwardOne/ToucanFwdIntroSceneOne";
import { ToucanFwdIntroStart } from "pages/private/interludes/ToucanGame/Forward/ForwardStarter/ToucanFwdIntroStart";
import { ToucanFwdIntroSceneTwo } from "pages/private/interludes/ToucanGame/Forward/ForwardTwo/ToucanFwdIntroSceneTwo";
//Toucan Reverse Intro scenes
import { ToucanRevIntroStart } from "pages/private/interludes/ToucanGame/Reverse/ReverseStarted/ToucanRevIntroStart";
//Toucan End Scenes
import { ToucanEndSceneOne } from "pages/private/interludes/ToucanGame/End/EndOne/ToucanEndSceneOne";
import { ToucanEndSceneTwo } from "pages/private/interludes/ToucanGame/End/EndTwo/ToucanEndSceneTwo";
//Toucan Spinoff Scenes
import { ToucanSpinoffSceneOne } from "pages/private/interludes/ToucanGame/Spinoff/SpinoffOne/ToucanSpinoffSceneOne";
import { ToucanSpinoffSceneThree } from "pages/private/interludes/ToucanGame/Spinoff/SpinoffThree/ToucanSpinoffSceneThree";
import { ToucanSpinoffSceneTwo } from "pages/private/interludes/ToucanGame/Spinoff/SpinoffTwo/ToucanSpinoffSceneTwo";
//Message scenes
import { MessageLastPage } from "pages/private/interludes/genericsInterludes/Message/MessageLastPage/MessageLastPage";
import { MessagePageOne } from "pages/private/interludes/genericsInterludes/Message/MessagePageOne/MessagePageOne";
import { MessagePageTwo } from "pages/private/interludes/genericsInterludes/Message/MessagePageTwo/MessagePageTwo";
//Sloth Forward Intro Scenes
import { SlothFwdIntroSceneOne } from "pages/private/interludes/SlothGame/Forward/ForwardOne/SlothFwdIntroSceneOne";
import { SlothFwdIntroStart } from "pages/private/interludes/SlothGame/Forward/ForwardStarted/SlothFwdIntroStart";
import { SlothFwdIntroSceneTwo } from "pages/private/interludes/SlothGame/Forward/ForwardTwo/SlothFwdIntroSceneTwo";
import { SlothRevIntroStart } from "pages/private/interludes/SlothGame/Reverse/ReverseStarted/SlothRevIntroStart";
//Sloth Game End Scenes
import { SlothEndSceneOne } from "pages/private/interludes/SlothGame/End/EndOne/SlothEndSceneOne";
import { SlothEndSceneTwo } from "pages/private/interludes/SlothGame/End/EndTwo/SlothGameEndTwo";
//Sloth Game Spinoff Scenes
import SlothSpinoffSceneOne from "pages/private/interludes/SlothGame/Spinoff/SpinoffOne/SlothSpinoffSceneOne";
import SlothSpinoffSceneThree from "pages/private/interludes/SlothGame/Spinoff/SpinoffThree/SlothSpinoffSceneThree";
import SlothSpinoffSceneTwo from "pages/private/interludes/SlothGame/Spinoff/SpinoffTwo/SlothSpinoffSceneTwo";

// Define an interface for component data
export interface ButtonI {
    label: string | string[];
    path: string;
}
export interface ComponentActions {
    close?: boolean;
    skip?: ButtonI;
    back?: ButtonI;
    next?: ButtonI;
}
export type ComponentActionsKeys = Exclude<keyof ComponentActions, "close">;
export interface ComponentData {
    component: React.ComponentType<any>;
    multipage?: number;
    actions: ComponentActions;
}
export interface SceneMap {
    [key: string]: ComponentData;
}
// Define an interface for game data
export interface GameData {
    forward: SceneMap;
    reverse: SceneMap;
    end: SceneMap;
    spinoff: SceneMap;
}
// Define an interface to store components data
export interface ComponentsData {
    intro: Partial<GameData>;
    parrot: GameData;
    toucan: GameData;
    sloth: GameData;
    message: Partial<GameData>;
}
// games scene paths
const parrotScenePath = `/${EnumPath.GAMES}/${EnumPath.PARROT_GAME}/${EnumPath.SCENE}`;
const toucanScenePath = `/${EnumPath.GAMES}/${EnumPath.TOUCAN_GAME}/${EnumPath.SCENE}`;
const slothScenePath = `/${EnumPath.GAMES}/${EnumPath.SLOTH_GAME}/${EnumPath.SCENE}`;
const messageScenePath = `/${EnumPath.GAMES}/${EnumPath.MESSAGE}`;
// button labels
const buttonLabels = {
    skip: "salta l'intro",
    next: "avanti",
    play: "giochiamo",
};
const { skip, next, play } = buttonLabels;
//skip to game buttons
const skipToParrotGameButtonNormal = {
    label: skip,
    path: `/${EnumPath.GAMES}/${EnumPath.PARROT_GAME}/${EnumPath.PLAY_NORMAL}`,
};
const skipToParrotGameButtonReverse = {
    label: skip,
    path: `/${EnumPath.GAMES}/${EnumPath.PARROT_GAME}/${EnumPath.PLAY_REVERSE}`,
};
const skipToToucanGameButtonNormal = {
    label: skip,
    path: `/${EnumPath.GAMES}/${EnumPath.TOUCAN_GAME}/${EnumPath.PLAY_NORMAL}`,
};
const skipToToucanGameButtonReverse = {
    label: skip,
    path: `/${EnumPath.GAMES}/${EnumPath.TOUCAN_GAME}/${EnumPath.PLAY_REVERSE}`,
};
const skipToSlothGameButtonNormal = {
    label: skip,
    path: `/${EnumPath.GAMES}/${EnumPath.SLOTH_GAME}/${EnumPath.PLAY_NORMAL}`,
};

//skip to scenes end buttons
const skipParrotEndScenesButton = {
    label: skip,
    path: `${parrotScenePath}/${EnumSceneFlow.END}/${SceneEnum.END_FOUR}`,
};
const skipParrotSpinoffScenesButton = {
    label: skip,
    path: `${parrotScenePath}/${EnumSceneFlow.SPINOFF}/${SceneEnum.SPINOFF_FIRST_FIVE}`,
};
const skipSlothEndScenesButton = {
    label: skip,
    path: `${slothScenePath}/${EnumSceneFlow.END}/${SceneEnum.END_TWO}`,
};
const skipSlothSpinoffScenesButton = {
    label: skip,
    path: `${slothScenePath}/${EnumSceneFlow.SPINOFF}/${SceneEnum.SPINOFF_FIRST_FOUR}`,
};
const skipMessageScenesButton = {
    label: skip,
    path: `${messageScenePath}${EnumSceneFlow.FORWARD}/${SceneEnum.MESSAGE_THREE}`,
};

export const componentsData: ComponentsData = {
    intro: {
        forward: {
            [SceneEnum.FORWARD_ONE]: {
                component: GameIntro,
                multipage: 2,
                actions: {
                    skip: {
                        label: skip,
                        path: `/${EnumPath.DASHBOARD}/${EnumPath.GAME_CHAPTERS}`,
                    },
                    next: {
                        label: "",
                        path: `/${EnumPath.DASHBOARD}/${EnumPath.GAME_CHAPTERS}`,
                    },
                },
            },
        },
    },
    parrot: {
        forward: {
            [SceneEnum.FORWARD_STARTED]: {
                component: ParrotFwdIntroStart,
                actions: {
                    close: true,
                    next: {
                        label: "",
                        path: `${parrotScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_ONE}`,
                    },
                },
            },
            [SceneEnum.FORWARD_ONE]: {
                component: ParrotFwdIntroSceneOne,
                multipage: 2,
                actions: {
                    close: true,
                    skip: skipToParrotGameButtonNormal,
                    back: {
                        label: "",
                        path: `${parrotScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_STARTED}`,
                    },
                    next: {
                        label: next,
                        path: `${parrotScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_TWO}`,
                    },
                },
            },
            [SceneEnum.FORWARD_TWO]: {
                component: ParrotFwdIntroSceneTwo,
                multipage: 2,
                actions: {
                    close: true,
                    skip: {
                        ...skipToParrotGameButtonNormal,
                        label: [skipToParrotGameButtonNormal.label, ""],
                    },
                    back: {
                        label: "",
                        path: `${parrotScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_ONE}`,
                    },
                    next: {
                        label: [next, play],
                        path: `/${EnumPath.GAMES}/${EnumPath.PARROT_GAME}/${EnumPath.PLAY_NORMAL}`,
                    },
                },
            },
        },
        reverse: {
            [SceneEnum.REVERSE_STARTED]: {
                component: ParrotRevIntroStart,
                actions: {
                    close: true,
                    skip: skipToParrotGameButtonReverse,
                    next: {
                        label: next,
                        path: `${parrotScenePath}/${EnumSceneFlow.REVERSE}/${SceneEnum.REVERSE_ONE}`,
                    },
                },
            },
            [SceneEnum.REVERSE_ONE]: {
                component: ParrotRevIntroSceneOne,
                multipage: 3,
                actions: {
                    close: true,
                    skip: skipToParrotGameButtonReverse,
                    back: {
                        label: "",
                        path: `${parrotScenePath}/${EnumSceneFlow.REVERSE}/${SceneEnum.REVERSE_STARTED}`,
                    },
                    next: {
                        label: next,
                        path: `${parrotScenePath}/${EnumSceneFlow.REVERSE}/${SceneEnum.REVERSE_TWO}`,
                    },
                },
            },
            [SceneEnum.REVERSE_TWO]: {
                component: ParrotRevIntroSceneTwo,
                multipage: 4,
                actions: {
                    close: true,
                    skip: {
                        ...skipToParrotGameButtonReverse,
                        label: [skipToParrotGameButtonReverse.label, ""],
                    },
                    back: {
                        label: "",
                        path: `${parrotScenePath}/${EnumSceneFlow.REVERSE}/${SceneEnum.REVERSE_ONE}`,
                    },
                    next: {
                        label: [next, play],
                        path: `/${EnumPath.GAMES}/${EnumPath.PARROT_GAME}/${EnumPath.PLAY_REVERSE}`,
                    },
                },
            },
        },
        end: {
            [SceneEnum.END_ONE]: {
                component: ParrotEndSceneOne,
                actions: {
                    close: true,
                    skip: skipParrotEndScenesButton,
                    next: {
                        label: next,
                        path: `${parrotScenePath}/${EnumSceneFlow.END}/${SceneEnum.END_TWO}`,
                    },
                },
            },
            [SceneEnum.END_TWO]: {
                component: ParrotEndSceneTwo,
                multipage: 2,
                actions: {
                    close: true,
                    skip: skipParrotEndScenesButton,
                    back: {
                        label: "",
                        path: `${parrotScenePath}/${EnumSceneFlow.END}/${SceneEnum.END_ONE}`,
                    },
                    next: {
                        label: next,
                        path: `${parrotScenePath}/${EnumSceneFlow.END}/${SceneEnum.END_THREE}`,
                    },
                },
            },
            [SceneEnum.END_THREE]: {
                component: ParrotEndSceneThree,
                actions: {
                    close: true,
                    skip: skipParrotEndScenesButton,
                    back: {
                        label: "",
                        path: `${parrotScenePath}/${EnumSceneFlow.END}/${SceneEnum.END_TWO}`,
                    },
                    next: {
                        label: next,
                        path: `${parrotScenePath}/${EnumSceneFlow.END}/${SceneEnum.END_FOUR}`,
                    },
                },
            },
            [SceneEnum.END_FOUR]: {
                component: ParrotEndSceneFour,
                actions: {
                    close: true,
                    next: {
                        label: "",
                        path: `${messageScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.MESSAGE_ONE}`,
                    },
                },
            },
        },
        spinoff: {
            [SceneEnum.SPINOFF_FIRST_ONE]: {
                component: ParrotSpinoffSceneOne,
                multipage: 2,
                actions: {
                    close: true,
                    skip: {
                        ...skipToParrotGameButtonNormal,
                        label: [skipToParrotGameButtonNormal.label, ""],
                    },
                    back: {
                        label: "",
                        path: "",
                    },
                    next: {
                        label: [next, play],
                        path: `/${EnumPath.GAMES}/${EnumPath.PARROT_GAME}/${EnumPath.PLAY_NORMAL}`,
                    },
                },
            },
            [SceneEnum.SPINOFF_FIRST_TWO]: {
                component: ParrotSpinoffSceneTwo,
                multipage: 2,
                actions: {
                    close: true,
                    skip: {
                        ...skipToParrotGameButtonReverse,
                        label: [skipToParrotGameButtonReverse.label, ""],
                    },
                    back: {
                        label: "",
                        path: "",
                    },
                    next: {
                        label: [next, play],
                        path: `/${EnumPath.GAMES}/${EnumPath.PARROT_GAME}/${EnumPath.PLAY_REVERSE}`,
                    },
                },
            },
            [SceneEnum.SPINOFF_FIRST_THREE]: {
                component: ParrotSpinoffSceneThree,
                actions: {
                    close: true,
                    skip: skipParrotSpinoffScenesButton,
                    next: {
                        label: next,
                        path: `${parrotScenePath}/${EnumSceneFlow.SPINOFF}/${SceneEnum.SPINOFF_FIRST_FOUR}`,
                    },
                },
            },
            [SceneEnum.SPINOFF_FIRST_FOUR]: {
                component: ParrotSpinoffSceneFour,
                actions: {
                    close: true,
                    skip: skipParrotSpinoffScenesButton,
                    back: {
                        label: "",
                        path: `${parrotScenePath}/${EnumSceneFlow.SPINOFF}/${SceneEnum.SPINOFF_FIRST_THREE}`,
                    },
                    next: {
                        label: next,
                        path: `${parrotScenePath}/${EnumSceneFlow.SPINOFF}/${SceneEnum.SPINOFF_FIRST_FIVE}`,
                    },
                },
            },
            [SceneEnum.SPINOFF_FIRST_FIVE]: {
                component: ParrotEndSceneFour,
                actions: {
                    next: {
                        label: "",
                        path: `/${EnumPath.GAMES}/${EnumPath.MESSAGE}/${SceneEnum.MESSAGE_ONE}`,
                    },
                },
            },
        },
    },
    toucan: {
        forward: {
            [SceneEnum.FORWARD_STARTED]: {
                component: ToucanFwdIntroStart,
                actions: {
                    close: true,
                    next: {
                        label: "",
                        path: `${toucanScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_ONE}`,
                    },
                },
            },
            [SceneEnum.FORWARD_ONE]: {
                component: ToucanFwdIntroSceneOne,
                multipage: 3,
                actions: {
                    close: true,
                    skip: skipToToucanGameButtonNormal,
                    back: {
                        label: "",
                        path: `${toucanScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_STARTED}`,
                    },
                    next: {
                        label: next,
                        path: `${toucanScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_TWO}`,
                    },
                },
            },
            [SceneEnum.FORWARD_TWO]: {
                component: ToucanFwdIntroSceneTwo,
                multipage: 3,
                actions: {
                    close: true,
                    back: {
                        label: "",
                        path: `${toucanScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_ONE}`,
                    },
                    skip: {
                        ...skipToToucanGameButtonNormal,
                        label: [skipToToucanGameButtonNormal.label, ""],
                    },
                    next: {
                        label: [next, play],
                        path: `/${EnumPath.GAMES}/${EnumPath.TOUCAN_GAME}/${EnumPath.PLAY_NORMAL}`,
                    },
                },
            },
        },
        reverse: {
            [SceneEnum.REVERSE_STARTED]: {
                component: ToucanRevIntroStart,
                multipage: 2,
                actions: {
                    close: true,
                    skip: {
                        ...skipToToucanGameButtonReverse,
                        label: [skipToToucanGameButtonReverse.label, ""],
                    },
                    back: {
                        label: "",
                        path: "",
                    },
                    next: {
                        label: [next, play],
                        path: `/${EnumPath.GAMES}/${EnumPath.TOUCAN_GAME}/${EnumPath.PLAY_REVERSE}`,
                    },
                },
            },
        },
        end: {
            [SceneEnum.END_ONE]: {
                component: ToucanEndSceneOne,
                actions: {
                    close: true,
                    next: {
                        label: next,
                        path: `${toucanScenePath}/${EnumSceneFlow.END}/${SceneEnum.END_TWO}`,
                    },
                },
            },
            [SceneEnum.END_TWO]: {
                component: ToucanEndSceneTwo,
                actions: {
                    next: {
                        label: "",
                        path: `${messageScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.MESSAGE_ONE}`,
                    },
                },
            },
        },
        spinoff: {
            [SceneEnum.SPINOFF_FIRST_ONE]: {
                component: ToucanSpinoffSceneOne,
                multipage: 2,
                actions: {
                    close: true,
                    skip: {
                        ...skipToToucanGameButtonNormal,
                        label: [skipToToucanGameButtonNormal.label, ""],
                    },
                    back: {
                        label: "",
                        path: "",
                    },
                    next: {
                        label: [next, play],
                        path: `/${EnumPath.GAMES}/${EnumPath.TOUCAN_GAME}/${EnumPath.PLAY_NORMAL}`,
                    },
                },
            },
            [SceneEnum.SPINOFF_FIRST_TWO]: {
                component: ToucanSpinoffSceneTwo,
                multipage: 2,
                actions: {
                    close: true,
                    skip: {
                        ...skipToToucanGameButtonNormal,
                        label: [skipToToucanGameButtonNormal.label, ""],
                    },
                    back: {
                        label: "",
                        path: "",
                    },
                    next: {
                        label: [next, play],
                        path: `/${EnumPath.GAMES}/${EnumPath.TOUCAN_GAME}/${EnumPath.PLAY_REVERSE}`,
                    },
                },
            },
            [SceneEnum.SPINOFF_FIRST_THREE]: {
                component: ToucanSpinoffSceneThree,
                actions: {
                    close: true,
                    next: {
                        label: next,
                        path: `${toucanScenePath}/${EnumSceneFlow.SPINOFF}/${SceneEnum.SPINOFF_FIRST_FOUR}`,
                    },
                },
            },
            [SceneEnum.SPINOFF_FIRST_FOUR]: {
                component: ToucanEndSceneTwo,
                actions: {
                    next: {
                        label: "",
                        path: `${messageScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.MESSAGE_ONE}`,
                    },
                },
            },
        },
    },
    sloth: {
        forward: {
            [SceneEnum.FORWARD_STARTED]: {
                component: SlothFwdIntroStart,
                actions: {
                    close: true,
                    next: {
                        label: "",
                        path: `${slothScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_ONE}`,
                    },
                },
            },
            [SceneEnum.FORWARD_ONE]: {
                component: SlothFwdIntroSceneOne,
                multipage: 3,
                actions: {
                    close: true,
                    skip: skipToSlothGameButtonNormal,
                    back: {
                        label: "",
                        path: `${slothScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_STARTED}`,
                    },
                    next: {
                        label: next,
                        path: `${slothScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_TWO}`,
                    },
                },
            },
            [SceneEnum.FORWARD_TWO]: {
                component: SlothFwdIntroSceneTwo,
                multipage: 3,
                actions: {
                    close: true,
                    skip: {
                        ...skipToSlothGameButtonNormal,
                        label: [skipToSlothGameButtonNormal.label, ""],
                    },
                    back: {
                        label: "",
                        path: `${slothScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_TWO}`,
                    },
                    next: {
                        label: next,
                        path: `/${EnumPath.GAMES}/${EnumPath.SLOTH_GAME}/${EnumPath.PLAY_NORMAL}`,
                    },
                },
            },
        },
        reverse: {
            [SceneEnum.REVERSE_STARTED]: {
                component: SlothRevIntroStart,
                actions: {
                    close: true,
                    next: {
                        label: "",
                        path: `/${EnumPath.GAMES}/${EnumPath.SLOTH_GAME}/${EnumPath.PLAY_REVERSE}`,
                    },
                },
            },
        },
        end: {
            [SceneEnum.END_ONE]: {
                component: SlothEndSceneOne,
                multipage: 3,
                actions: {
                    close: true,
                    skip: {
                        ...skipSlothEndScenesButton,
                        label: [skipSlothEndScenesButton.label, ""],
                    },
                    back: {
                        label: "",
                        path: "",
                    },
                    next: {
                        label: next,
                        path: `${slothScenePath}/${EnumSceneFlow.END}/${SceneEnum.END_TWO}`,
                    },
                },
            },
            [SceneEnum.END_TWO]: {
                component: SlothEndSceneTwo,
                actions: {
                    next: {
                        label: "",
                        path: `${messageScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.MESSAGE_ONE}`,
                    },
                },
            },
        },
        spinoff: {
            [SceneEnum.SPINOFF_FIRST_ONE]: {
                component: SlothSpinoffSceneOne,
                multipage: 3,
                actions: {
                    close: true,
                    skip: {
                        ...skipToSlothGameButtonNormal,
                        label: [skipToSlothGameButtonNormal.label, ""],
                    },
                    back: {
                        label: "",
                        path: "",
                    },
                    next: {
                        label: [next, play],
                        path: `/${EnumPath.GAMES}/${EnumPath.SLOTH_GAME}/${EnumPath.PLAY_NORMAL}`,
                    },
                },
            },
            [SceneEnum.SPINOFF_FIRST_TWO]: {
                component: SlothSpinoffSceneTwo,
                actions: {
                    close: true,
                    next: {
                        label: next,
                        path: `/${EnumPath.GAMES}/${EnumPath.SLOTH_GAME}/${EnumPath.PLAY_REVERSE}`,
                    },
                },
            },
            [SceneEnum.SPINOFF_FIRST_THREE]: {
                component: SlothSpinoffSceneThree,
                multipage: 3,
                actions: {
                    close: true,
                    skip: {
                        ...skipSlothSpinoffScenesButton,
                        label: [skipSlothSpinoffScenesButton.label, ""],
                    },
                    back: {
                        label: "",
                        path: "",
                    },
                    next: {
                        label: next,
                        path: `${slothScenePath}/${EnumSceneFlow.SPINOFF}/${SceneEnum.SPINOFF_FIRST_FOUR}`,
                    },
                },
            },
            [SceneEnum.SPINOFF_FIRST_FOUR]: {
                component: SlothEndSceneTwo,
                actions: {
                    next: {
                        label: "",
                        path: `${messageScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.MESSAGE_ONE}`,
                    },
                },
            },
        },
    },
    message: {
        forward: {
            [SceneEnum.MESSAGE_ONE]: {
                component: MessagePageOne,
                multipage: 2,
                actions: {
                    close: true,
                    skip: skipMessageScenesButton,
                    back: {
                        label: "",
                        path: "",
                    },
                    next: {
                        label: next,
                        path: `${messageScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.MESSAGE_TWO}`,
                    },
                },
            },
            [SceneEnum.MESSAGE_TWO]: {
                component: MessagePageTwo,
                multipage: 2,
                actions: {
                    close: true,
                    skip: {
                        ...skipMessageScenesButton,
                        label: [skipMessageScenesButton.label, ""],
                    },
                    back: {
                        label: "",
                        path: `${messageScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.MESSAGE_ONE}`,
                    },
                    next: {
                        label: next,
                        path: `${messageScenePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.MESSAGE_THREE}`,
                    },
                },
            },
            [SceneEnum.MESSAGE_THREE]: {
                component: MessageLastPage,
                actions: {
                    close: true,
                },
            },
        },
    },
};
