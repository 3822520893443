import lulaOnTree from "assets/img/sloth/elements/lula-end-spinoff-2.svg";
import greetingSonny from "assets/img/sloth/elements/sonny-end-spinoff-1.svg";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { SpeechSynthesis } from "components/SpeechSynthesis/SpeechSynthesis";
import { useEffectAudio } from "hook/useEffectAudio";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { ChapterSceneFC, IMultiOptionScene, SceneDataT } from "models/ChapterSceneFC";
import { FC, useMemo } from "react";
import getClasses from "utils/getClasses";
import { getTransitionClass } from "utils/getTransitionClass";
import "./SlothSpinoffSceneThree.scss";
import { useGamePath } from "hook/useGamePath";

const SlothSpinoffSceneThreeData: IMultiOptionScene = {
  "spinoffFirst-three": [
    {
      title: "",
      text: [],
      images: {},
    },
    {
      title: "",
      text: [],
      images: {},
    },
    {
      title: "Sonny",
      text: ["Finalmente ho di nuovo un posto preferito dove tornare a dormire! Grazie!"],
      images: {
        sonny: greetingSonny,
      },
    },
  ],
  "spinoffSecond-three": [
    {
      title: "",
      text: [],
      images: {},
    },
    {
      title: "",
      text: [],
      images: {},
    },
    {
      title: "Lula",
      text: ["Il mio nuovo albero è bellissimo. Non so come ringraziarti!"],
      images: {
        lula: lulaOnTree,
      },
    },
  ],
};

const SlothSpinoffSceneThree: FC<Partial<ChapterSceneFC>> = ({ out, page = 1, action }) => {
  const { volumeVoice } = useEffectAudio();
  const { currentSceneId } = useGamePath();

  const contents: SceneDataT =
    SlothSpinoffSceneThreeData[currentSceneId || "spinoffFirst-three"];
  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });

  const renderSpeech = useMemo(() => {
    return (
      Boolean(volumeVoice) &&
      page && (
        <SpeechSynthesis
          notVisible
          texts={[...contents[page - 1].text]}
          setFinished={() => setIsFinished(true)}
        />
      )
    );
  }, [contents, volumeVoice, page, setIsFinished]);

  return (
    <div
      className={`container-spinoff-three fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      <div
        className={`content ${currentSceneId === "spinoffFirst-three" ? "first" : "second"}`}
      >
        {[1, 2].includes(page) && (
          <div
            className={`bg-first 
            ${getClasses({
              "fade-in-image": page === 1,
              "fade-out-image": page === 2,
            })}`}
          />
        )}
        {Array.from({ length: contents.length }).map((_, pageDiv) => {
          const {
            title,
            text,
            images: { sonny, lula },
          } = contents[pageDiv];
          return (
            page === pageDiv + 1 && (
              <div
                key={`spinoff-page-${page}`}
                className={`page-container ${getTransitionClass([page, pageDiv + 1])}`}
              >
                {page === 3 && (
                  <>
                    <div className="balloon-container">
                      <SimpleCard title={title} text={text} />
                      {renderSpeech}
                    </div>
                    <img
                      src={currentSceneId === "spinoffFirst-three" ? sonny : lula}
                      className="sloth-figure"
                      alt="out sloth friend"
                    />
                  </>
                )}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default SlothSpinoffSceneThree;
