import bradyNap from "assets/img/sloth/elements/brady-on-the-tree-starter.svg";
import { ActionCard } from "components/ActionCard/ActionCard";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { CardIconEnum } from "models/CardIconEnum";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import { FC, useMemo } from "react";
import getClasses from "utils/getClasses";
import "./SlothFwdIntroStart.scss";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";
/**
 * SlothFwdIntroStart Component
 *
 * This component represents a started chapter scene of forward flow.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @param {boolean} action - Invokes the function that changes component.
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const slothFwdIntroStartData: SceneDataT = [
  {
    title: "Il pisolino interrotto del bradipo",
    text: [
      "Brady è un bradipo che adora passare le sue giornate sonnecchiando sul suo ramo preferito.",
      "Un giorno però, durante la ricerca di un albero su cui riposare, si accorge che qualcosa non torna: tutti gli alberi sono scomparsi!",
    ],
    audio: {
      narrator: "sloth/narrator-01",
    },
    images: {
      brady: bradyNap,
    },
  },
];

export const SlothFwdIntroStart: FC<Partial<ChapterSceneFC>> = ({ out, page = 1, action }) => {
  const contents = slothFwdIntroStartData;
  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });
  const [
    {
      title,
      text,
      images: { brady },
    },
  ] = contents;

  const renderSpeech = useMemo(
    () => (
      <SpeechAudio
        notVisible
        audioFiles={[contents[0].audio!.narrator]}
        start={Boolean(page)}
        setFinished={() => setIsFinished(true)}
        endDelay={2000}
        startDelay={1000}
      />
    ),
    [contents, page, setIsFinished]
  );

  return (
    <div
      className={`sloth-intro-start fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      <div className="card-center">
        {renderSpeech}
        <ActionCard iconImg={CardIconEnum.LENS} title={title} text={text} action={action} />
      </div>
      <img className="brady-figure" src={brady} alt="brady is chilling on branch" />
    </div>
  );
};
