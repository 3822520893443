import memoFelix from "assets/img/common/Detective/detective-memo-reverse.svg";
import blinkingChest from "assets/img/parrot/elements/casket.svg";
import foregroundPlant from "assets/img/parrot/elements/story-three-f-plant.svg";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import { FC, useMemo } from "react";
import getClasses from "utils/getClasses";
import { getTransitionClass } from "utils/getTransitionClass";
import "./ParrotFwdIntroSceneTwo.scss";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";

/**
 * ParrotFwdIntroSceneTwo Component
 *
 * This component represents a third chapter scene of forward flow.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @param {number} page - Indicates number of the scene inside component
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const parrotFwIntroSceneTwoData: SceneDataT = [
  {
    title: "Detective Felix",
    text: ["Raccogliamo il primo indizio. Clicca sul forziere per scoprire come."],
    images: {},
    audio: {
      felix: "parrot/detective-felix-02",
    },
  },
  {
    title: "Detective Felix",
    text: [
      "Per aprire il forziere, memorizza i quadrati che si illuminano e poi toccali nell’ordine corretto.",
    ],
    images: {
      felix: memoFelix,
    },
    audio: {
      felix: "parrot/detective-felix-03",
    },
  },
];
export const ParrotFwdIntroSceneTwo: FC<ChapterSceneFC> = ({ out, page = 1, action }) => {
  const contents = parrotFwIntroSceneTwoData;
  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action!, avoid: [1] });

  const renderSpeech = useMemo(
    () => (
      <SpeechAudio
        notVisible
        audioFiles={[parrotFwIntroSceneTwoData[page - 1].audio!.felix]}
        start={Boolean(page)}
        setFinished={() => page === 2 && setIsFinished(true)}
      />
    ),
    [page, setIsFinished]
  );

  return (
    <div
      className={`parrot-intro-scene-two fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      <div className="content">
        <img
          className="chest"
          src={blinkingChest}
          alt="A bright and blinking chest"
          onClick={page === 1 ? action : undefined}
        />
        <img className="foreground-plant" src={foregroundPlant} alt="a leaf above the chest" />
        {Array.from({ length: parrotFwIntroSceneTwoData.length }).map((_, pageDiv) => {
          const {
            title,
            text,
            images: { felix },
          } = parrotFwIntroSceneTwoData[pageDiv];
          return (
            page === pageDiv + 1 && (
              <div
                key={`page ${pageDiv + 1}`}
                className={`page-container ${getTransitionClass([page, pageDiv + 1])}`}
              >
                {pageDiv === 1 && (
                  <>
                    <div className="background-overlay"></div>
                    <div className="chest-alone"></div>
                  </>
                )}
                <div className={`balloon-container balloon-${pageDiv + 1}`}>
                  <SimpleCard title={title} text={text} />
                </div>
                {renderSpeech}
                {pageDiv === 1 && <img className="felix-figure" src={felix} alt="Felix" />}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};
