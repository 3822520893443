export enum EnumSecondGameSquare {
    BEE = "ape",
    BEARD = "barba",
    BOAT = "barca",
    BAG = "borsa",
    CATERPILLAR = "bruco",
    SOCK = "calza",
    DOG = "cane",
    GOAT = "capra",
    MEAT = "carne",
    HOME = "casa",
    CIRCUS = "circo",
    DICE = "dado",
    TOOTH = "dente",
    FINGER = "dito",
    GIFT = "regalo",
    DRAGON = "drago",
    GRASS = "erba",
    LIGHTHOUSE = "faro",
    FAIRY = "fata",
    THREAD = "filo",
    SEAL = "foca",
    SMOKE = "fumo",
    MUSHROOM = "fungo",
    LEG = "gamba",
    CAT = "gatto",
    WHEAT = "grano",
    OWL = "gufo",
    LIGHTNING = "lampo",
    WOOL = "lana",
    BOOK = "libro",
    MOON = "luna",
    WOLF = "lupo",
    WIZARD = "mago",
    HAND = "mano",
    SEA = "mare",
    APPLE = "mela",
    MOUNT = "monte",
    BLACKBERRIES = "more",
    FLY = "mosca",
    MOTORBIKE = "moto",
    WALL = "muro",
    DWARF = "nano",
    NOSE = "naso",
    SHIP = "nave",
    SNOW = "neve",
    NEST = "nido",
    WALNUTS = "noci",
    KNOT = "nodo",
    NOTES = "note",
    GOOSE = "oca",
    BEAR = "orso",
    BALL = "palla",
    BREAD = "pane",
    PEAR = "pera",
    FISH = "pesce",
    PINE = "pino",
    PIPE = "pipa",
    BRIDGE = "ponte",
    DOOR = "porta",
    BRANCH = "ramo",
    FROG = "rana",
    OAR = "remo",
    RICE = "riso",
    ROSE = "rosa",
    SALT = "sale",
    LADDER = "scala",
    BROOM = "scopa",
    SUN = "sole",
    SWORD = "spada",
    MOUSE = "topo",
    BULL = "toro",
    CAKE = "torta",
    TRAIN = "treno",
    TUBE = "tubo",
    GRAPES = "uva",
    BATH = "vasca da bagno",
    POT = "vaso",
    PAW = "zampa",
    ZEBRA = "zebra",
}

export enum EnumSecondGameAudio {
    BEE = "toucan/second-game-words/Paradigma_P_1",
    BEARD = "toucan/second-game-words/Paradigma_P_2",
    BOAT = "toucan/second-game-words/Paradigma_P_3",
    BAG = "toucan/second-game-words/Paradigma_P_4",
    CATERPILLAR = "toucan/second-game-words/Paradigma_P_5",
    SOCK = "toucan/second-game-words/Paradigma_P_6",
    DOG = "toucan/second-game-words/Paradigma_P_7",
    GOAT = "toucan/second-game-words/Paradigma_P_8",
    MEAT = "toucan/second-game-words/Paradigma_P_9",
    HOME = "toucan/second-game-words/Paradigma_P_10",
    CIRCUS = "toucan/second-game-words/Paradigma_P_11",
    DICE = "toucan/second-game-words/Paradigma_P_12",
    TOOTH = "toucan/second-game-words/Paradigma_P_13",
    FINGER = "toucan/second-game-words/Paradigma_P_14",
    GIFT = "toucan/second-game-words/Paradigma_P_15",
    DRAGON = "toucan/second-game-words/Paradigma_P_16",
    GRASS = "toucan/second-game-words/Paradigma_P_17",
    LIGHTHOUSE = "toucan/second-game-words/Paradigma_P_18",
    FAIRY = "toucan/second-game-words/Paradigma_P_19",
    THREAD = "toucan/second-game-words/Paradigma_P_20",
    SEAL = "toucan/second-game-words/Paradigma_P_21",
    SMOKE = "toucan/second-game-words/Paradigma_P_22",
    MUSHROOM = "toucan/second-game-words/Paradigma_P_23",
    LEG = "toucan/second-game-words/Paradigma_P_24",
    CAT = "toucan/second-game-words/Paradigma_P_25",
    WHEAT = "toucan/second-game-words/Paradigma_P_26",
    OWL = "toucan/second-game-words/Paradigma_P_27",
    LIGHTNING = "toucan/second-game-words/Paradigma_P_28",
    WOOL = "toucan/second-game-words/Paradigma_P_29",
    BOOK = "toucan/second-game-words/Paradigma_P_30",
    MOON = "toucan/second-game-words/Paradigma_P_31",
    WOLF = "toucan/second-game-words/Paradigma_P_32",
    WIZARD = "toucan/second-game-words/Paradigma_P_33",
    HAND = "toucan/second-game-words/Paradigma_P_34",
    SEA = "toucan/second-game-words/Paradigma_P_35",
    APPLE = "toucan/second-game-words/Paradigma_P_36",
    MOUNT = "toucan/second-game-words/Paradigma_P_37",
    BLACKBERRIES = "toucan/second-game-words/Paradigma_P_38",
    FLY = "toucan/second-game-words/Paradigma_P_39",
    MOTORBIKE = "toucan/second-game-words/Paradigma_P_40",
    WALL = "toucan/second-game-words/Paradigma_P_41",
    DWARF = "toucan/second-game-words/Paradigma_P_42",
    NOSE = "toucan/second-game-words/Paradigma_P_43",
    SHIP = "toucan/second-game-words/Paradigma_P_44",
    SNOW = "toucan/second-game-words/Paradigma_P_45",
    NEST = "toucan/second-game-words/Paradigma_P_46",
    WALNUTS = "toucan/second-game-words/Paradigma_P_47",
    KNOT = "toucan/second-game-words/Paradigma_P_48",
    NOTES = "toucan/second-game-words/Paradigma_P_49",
    GOOSE = "toucan/second-game-words/Paradigma_P_50",
    BEAR = "toucan/second-game-words/Paradigma_P_51",
    BALL = "toucan/second-game-words/Paradigma_P_52",
    BREAD = "toucan/second-game-words/Paradigma_P_53",
    PEAR = "toucan/second-game-words/Paradigma_P_54",
    FISH = "toucan/second-game-words/Paradigma_P_55",
    PINE = "toucan/second-game-words/Paradigma_P_56",
    PIPE = "toucan/second-game-words/Paradigma_P_57",
    BRIDGE = "toucan/second-game-words/Paradigma_P_58",
    DOOR = "toucan/second-game-words/Paradigma_P_59",
    BRANCH = "toucan/second-game-words/Paradigma_P_60",
    FROG = "toucan/second-game-words/Paradigma_P_61",
    OAR = "toucan/second-game-words/Paradigma_P_62",
    RICE = "toucan/second-game-words/Paradigma_P_63",
    ROSE = "toucan/second-game-words/Paradigma_P_64",
    SALT = "toucan/second-game-words/Paradigma_P_65",
    LADDER = "toucan/second-game-words/Paradigma_P_66",
    BROOM = "toucan/second-game-words/Paradigma_P_67",
    SUN = "toucan/second-game-words/Paradigma_P_68",
    SWORD = "toucan/second-game-words/Paradigma_P_69",
    MOUSE = "toucan/second-game-words/Paradigma_P_70",
    BULL = "toucan/second-game-words/Paradigma_P_71",
    CAKE = "toucan/second-game-words/Paradigma_P_72",
    TRAIN = "toucan/second-game-words/Paradigma_P_73",
    TUBE = "toucan/second-game-words/Paradigma_P_74",
    GRAPES = "toucan/second-game-words/Paradigma_P_75",
    BATH = "toucan/second-game-words/Paradigma_P_76",
    POT = "toucan/second-game-words/Paradigma_P_77",
    PAW = "toucan/second-game-words/Paradigma_P_78",
    ZEBRA = "toucan/second-game-words/Paradigma_P_79",
}

export type ValueMatrixFromEnum<K> = K extends Record<string, any> ? (keyof K)[][] : undefined;
