import { FC, useEffect, useMemo, useState } from "react";
import { Howl } from "howler";
import { RoundButton } from "components/RoundButton/RoundButton";
import { useEffectAudio } from "hook/useEffectAudio";

interface SpeechAudioFC {
  audioFiles: string[];
  notVisible?: boolean;
  start?: boolean;
  setFinished?: () => void;
  endDelay?: number;
  startDelay?: number;
}

export const SpeechAudio: FC<Partial<SpeechAudioFC>> = ({
  audioFiles = [],
  start,
  notVisible,
  setFinished,
  endDelay = 0,
  startDelay,
}) => {
  const [currentAudioIndex, setCurrentAudioIndex] = useState(0);
  const [isFinished, setIsFinished] = useState(false);
  const [shouldStart, setShouldStart] = useState(false);

  const { volumeVoice } = useEffectAudio();
  const toSpeak = audioFiles[currentAudioIndex];

  const handlePauseAfterAudio = (callback: () => void, delay: number) => {
    if (delay > 0)
      setTimeout(() => {
        callback();
      }, delay);
    else callback();
  };
  const audio = useMemo(() => {
    if (!toSpeak) return null;

    try {
      const src = require(`assets/audio/specheed/${toSpeak}.m4a`);

      return new Howl({
        src,
        volume: volumeVoice,
        onend: () => {
          handlePauseAfterAudio(() => {
            setCurrentAudioIndex((prevIndex) => {
              if (prevIndex === audioFiles.length - 1) {
                setIsFinished(true);
                return prevIndex;
              }
              return prevIndex + 1;
            });
          }, endDelay);
        },
      });
    } catch (error) {
      console.error("Audio file not found:", toSpeak);

      return null;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioFiles.length, toSpeak]);

  useEffect(() => {
    if (start) {
      if (startDelay !== undefined) {
        setTimeout(() => setShouldStart(true), startDelay);
      } else {
        setShouldStart(true);
      }
    } else {
      setShouldStart(false);
    }
  }, [start, startDelay]);

  useEffect(() => {
    if (!audio) return;
    if (audioFiles.length > 0 && shouldStart && !audio.playing()) audio.play();

    return () => {
      audio.stop();
    };
  }, [audio, audioFiles, currentAudioIndex, shouldStart, start]);

  useEffect(() => {
    if (!audio) return;
    audio.volume(volumeVoice);
  }, [audio, volumeVoice]);

  useEffect(() => {
    if (isFinished && setFinished) {
      setFinished();
    }
  }, [isFinished, setFinished]);

  return (
    <div>
      {notVisible || (
        <RoundButton
          type="replay"
          action={() => {
            setCurrentAudioIndex(0);
            audio && audio?.play();
          }}
        />
      )}
    </div>
  );
};
