import React, { FC, useMemo } from "react";
import "./ParrotEndSceneFour.scss";
import { ChapterSceneFC } from "models/ChapterSceneFC";
import { ActionCard } from "components/ActionCard/ActionCard";
import getClasses from "utils/getClasses";
import { useDispatch } from "react-redux";
import { setCurrentScene } from "store/slices/modeGameSlice";
import { SceneEnum } from "models/EnumScene";
import cloud from "assets/img/parrot/elements/cloud.svg";
import happyPina from "assets/img/parrot/elements/monkey-happy.svg";
import foreGroundPlants from "assets/img/parrot/elements/plant-end-game.svg";
import { CardIconEnum } from "models/CardIconEnum";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";

/**
 * ParrotEndSceneFour Component
 *
 * This component represents the fifth and final part of the end of a chapter in the story.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @returns {JSX.Element} - The rendered component.
 */

const parrotEndSceneFourData = [
  {
    title: "Ce l'hai fatta!",
    text: [
      "Congratulazioni, hai risolto il primo caso! Finalmente i pappagalli sono tornati nella foresta, liberi e felici.",
      "Mentre eri via con Felix ho trovato questo messaggio da parte del colpevole.",
    ],
    images: {
      pina: happyPina,
    },
    audio: {
      pina: "parrot/monkey-pina-02",
    },
  },
];
export const ParrotEndSceneFour: FC<Partial<ChapterSceneFC>> = React.memo(({ out, action }) => {
  const [
    {
      title,
      text,
      images: { pina },
      audio,
    },
  ] = parrotEndSceneFourData;
  const dispatch = useDispatch();

  const goToMessage = () => {
    dispatch(setCurrentScene(SceneEnum.MESSAGE_ONE));
    action && action();
  };

  const renderAudio = useMemo(
    () => <SpeechAudio start audioFiles={[`${audio.pina}`]} notVisible />,
    [audio.pina]
  );

  return (
    <div
      className={`parrot-end-four fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      {[2, 1].map((item) => (
        <img
          key={`cloud-${item}`}
          className={`cloud cloud-${item} fade-in-image`}
          src={cloud}
          alt="cloud in the sky behind the card"
        />
      ))}
      {renderAudio}
      <div className="card-center fade-in-image">
        <ActionCard
          iconImg={CardIconEnum.BAG}
          title={title}
          isEndGame
          text={text}
          buttonText="leggi il messaggio"
          action={goToMessage}
        />
      </div>
      <img
        className="foreground-plants fade-in-image"
        src={foreGroundPlants}
        alt="Green plants on foreground"
      />
      <img className="pina fade-in-image" src={pina} alt="Happy jumping Pina" />
    </div>
  );
});
