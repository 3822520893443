import happyBrady from "assets/img/sloth/elements/happy-brady.svg";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import { FC, useMemo } from "react";
import getClasses from "utils/getClasses";
import { getTransitionClass } from "utils/getTransitionClass";
import "./SlothEndSceneOne.scss";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";

/**
 * SlothEndSceneOne Component
 *
 * This component represents a first chapter scene of forward flow.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @param {boolean} page - Indicates number of the scene inside component.
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const slothEndSceneOneData: SceneDataT = [
  {
    title: "",
    text: [],
    images: {},
  },
  {
    title: "",
    text: [],
    images: {},
  },
  {
    title: "Brady",
    text: [
      "Ecco il primo albero!",
      "Non vedo l'ora di arrampicarmi e fare un lunghissimo riposino",
    ],
    audio: {
      brady: "sloth/brady-02",
    },
    images: {
      brady: happyBrady,
    },
  },
];

export const SlothEndSceneOne: FC<Partial<ChapterSceneFC>> = ({ out, page = 1, action }) => {
  const contents = slothEndSceneOneData;
  const {
    title,
    text,
    images: { brady },
  } = contents[2];
  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });

  const renderSpeech = useMemo(
    () => (
      <SpeechAudio
        notVisible
        audioFiles={[contents[2].audio!.brady]}
        start={Boolean(page)}
        setFinished={() => setIsFinished(true)}
        endDelay={1000}
        startDelay={1000}
      />
    ),
    [contents, page, setIsFinished]
  );

  return (
    <div
      className={`sloth-end-container fade-in-image ${getClasses({
        "fade-out-image": out && page === 3,
      })}`}
    >
      <div className="content">
        {page === 1 && <div className={`background bg-1 ${getTransitionClass([page!, 1])}`} />}
        {page >= 2 && <div className={`background bg-2 fade-in-image`} />}
        {page === 3 && (
          <div className={`page-container ${getTransitionClass([page, 3])}`}>
            <img className="brady-figure" src={brady} alt="brady" />
            <div className="balloon-container">
              <SimpleCard title={title} text={text} />
            </div>
            {renderSpeech}
          </div>
        )}
      </div>
    </div>
  );
};
