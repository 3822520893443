import { SimpleCard } from "components/SimpleCard/SimpleCard";
import "./ParrotFwdIntroSceneOne.scss";
import { FC, useMemo } from "react";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import getClasses from "utils/getClasses";
import { getTransitionClass } from "utils/getTransitionClass";
import backgroundPlant from "assets/img/parrot/elements/plant-parrots.svg";
import foreGroundPlant from "assets/img/parrot/elements/story-one-plant.svg";
import worriedPina from "assets/img/parrot/elements/monkey-worried.svg";
import confusedPina from "assets/img/parrot/elements/monkey-unaware.svg";
import thinkingFelix from "assets/img/common/Detective/detective-doubt.svg";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";
/**
 * ParrotFwdIntroSceneOne Component
 *
 * This component represents a first chapter scene of forward flow.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const parrotFwIntroSceneOneData: SceneDataT = [
  {
    title: "Pina la scimmia cappuccina",
    text: [
      "Dove sono finiti tutti i pappagalli della foresta? Adesso è così silenziosa e spenta.",
      "Chiederò aiuto al detective Felix!",
    ],
    images: {
      pina: worriedPina,
    },
    audio: {
      pina: "parrot/monkey-pina-01",
    },
  },
  {
    title: "Detective Felix",
    text: [
      "Per risolvere il caso della scomparsa dei pappagalli avrò bisogno di un assistente.",
      "Ti va di aiutarmi?",
    ],
    images: {
      pina: confusedPina,
      felix: thinkingFelix,
    },
    audio: {
      felix: "parrot/detective-felix-01",
    },
  },
];
export const ParrotFwdIntroSceneOne: FC<Partial<ChapterSceneFC>> = ({
  out,
  page = 1,
  action,
}) => {
  const contents = parrotFwIntroSceneOneData;
  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });

  const renderSpeech = useMemo(
    () => (
      <SpeechAudio
        notVisible
        audioFiles={[
          ...(page === 1 ? [parrotFwIntroSceneOneData[0].audio!.pina] : []),
          ...(page === 2 ? [parrotFwIntroSceneOneData[1].audio!.felix] : []),
        ]}
        start={Boolean(page)}
        setFinished={() => setIsFinished(true)}
      />
    ),
    [page, setIsFinished]
  );
  return (
    <div
      className={`parrot-intro-scene-one fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      <div className="content">
        {Array.from({ length: parrotFwIntroSceneOneData.length }).map((_, pageDiv) => {
          const {
            title,
            text,
            images: { felix, pina },
          } = parrotFwIntroSceneOneData[pageDiv];
          return (
            page === pageDiv + 1 && (
              <div
                key={`page ${pageDiv + 1}`}
                className={`page-container page-${pageDiv + 1} ${getTransitionClass([
                  page,
                  pageDiv + 1,
                ])}`}
              >
                <img className={`pina-figure pina-${pageDiv + 1}`} src={pina} alt="Pina" />
                <img
                  className={`foreground-plant page-${pageDiv + 1}`}
                  src={pageDiv === 0 ? foreGroundPlant : backgroundPlant}
                  alt="A green plant in foreground"
                />
                <div className={`balloon-container balloon-${pageDiv + 1}`}>
                  <SimpleCard title={title} text={text} />
                </div>
                {renderSpeech}
                {pageDiv === 1 && (
                  <img className="felix-figure" src={felix} alt="Thinking Felix" />
                )}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};
