import foregroundPlant from "assets/img/parrot/elements/chapter-plant.svg";
import topImage from "assets/img/parrot/elements/chapter-started-top.svg";
import lutino from "assets/img/parrot/elements/lutino.svg";
import trunk from "assets/img/parrot/elements/trunk.svg";
import { ActionCard } from "components/ActionCard/ActionCard";

import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { CardIconEnum } from "models/CardIconEnum";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import { FC, useMemo } from "react";
import getClasses from "utils/getClasses";
import "./ParrotFwdIntroStart.scss";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";

/**
 * ParrotFwdIntroStart Component
 *
 * This component represents a started chapter scene of forward flow.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @param {boolean} action - Invokes the function that changes component.
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const parrotFwIntroStartData: SceneDataT = [
  {
    title: "La scomparsa dei pappagalli",
    text: [
      "Tra gli alberi e il verde incontaminato della foresta tropicale di Eufauna, cantano e svolazzano felici degli esemplari di pappagalli dalle coloratissime piume. Adesso però pare siano scomparsi nel nulla.",
    ],
    audio: {
      narrator: "parrot/narrator-01",
    },
    images: {},
  },
];
export const ParrotFwdIntroStart: FC<Partial<ChapterSceneFC>> = ({ out, page = 1, action }) => {
  const contents = parrotFwIntroStartData;
  const [{ title, text }] = contents;
  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });

  const renderSpeech = useMemo(
    () => (
      <SpeechAudio
        notVisible
        audioFiles={[contents[0].audio!.narrator]}
        start={Boolean(page)}
        setFinished={() => setIsFinished(true)}
      />
    ),
    [contents, page, setIsFinished]
  );
  return (
    <div
      className={`chapter-started-parrot fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      {renderSpeech}
      <img src={trunk} className="trunk-image" alt="A Trunk that where Lutino leans" />
      <div className="card-center">
        <ActionCard
          iconImg={CardIconEnum.LENS}
          data-cy="test-action-card"
          title={title}
          buttonText="Avanti"
          text={text}
          action={action}
        />
      </div>
      <img src={topImage} className="top-image" alt="Blue Parrot on trunk with lianas" />
      <img src={lutino} className="lutino-figure" alt="Lutino a Yellow Parrot" />
      <img src={foregroundPlant} className="foreground-image" alt="A Green Plant" />
    </div>
  );
};
