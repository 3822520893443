import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { Home } from "../pages/private/Home/Home";
import PrivateRoutes from "../utils/PrivateRoutes";
import { LoginPage } from "../pages/public/LoginPage/LoginPage";
import { GameChapters } from "../pages/private/gameChapters/GameChapters";
import { GamesContainer } from "../pages/private/Games/Container/GamesContainer";
import { ContainerGame } from "../pages/private/Games/Container/ContainerGame";
import { InterludesContainer } from "../pages/private/interludes/InterludesContainer";
import { EnumGame, EnumPath } from "../models/EnumPath";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        {/* Routes Protette */}
        <Route element={<PrivateRoutes route="auth" />}>
          <Route
            path={EnumPath.DASHBOARD}
            element={
              <>
                <Home />
                <Navigate to={`/${EnumPath.DASHBOARD}/${EnumPath.GAME_CHAPTERS}`} replace />
              </>
            }
          >
            <Route path={EnumPath.GAME_CHAPTERS} element={<GameChapters />} />
            <Route
              path={`${EnumPath.INTRO}/:sceneFlow/:id`}
              element={<InterludesContainer game="intro" />}
            />
          </Route>

          {/* Games */}
          <Route path={EnumPath.GAMES} element={<GamesContainer />}>
            <Route
              path={`${EnumPath.MESSAGE}/:sceneFlow/:id`}
              element={<InterludesContainer game="message" />}
            />

            <Route
              path={`${EnumPath.PARROT_GAME}/:mode`}
              element={<ContainerGame game={EnumGame.PARROT_GAME} />}
            />
            <Route
              path={`${EnumPath.PARROT_GAME}/${EnumPath.SCENE}/:sceneFlow/:id`}
              element={<InterludesContainer game="parrot" />}
            />

            <Route
              path={`${EnumPath.TOUCAN_GAME}/:mode`}
              element={<ContainerGame game={EnumGame.TOUCAN_GAME} />}
            />
            <Route
              path={`${EnumPath.TOUCAN_GAME}/${EnumPath.SCENE}/:sceneFlow/:id`}
              element={<InterludesContainer game="toucan" />}
            />
            <Route
              path={`${EnumPath.SLOTH_GAME}/:mode`}
              element={<ContainerGame game={EnumGame.SLOTH_GAME} />}
            />
            <Route
              path={`${EnumPath.SLOTH_GAME}/${EnumPath.SCENE}/:sceneFlow/:id`}
              element={<InterludesContainer game="sloth" />}
            />
          </Route>

          <Route path="" element={<Navigate to={`/${EnumPath.DASHBOARD}`} replace />} />
        </Route>

        {/* Routes Pubbliche */}
        <Route element={<PrivateRoutes route="no-auth" />}>
          <Route path={EnumPath.LOGIN} element={<LoginPage />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
