import React, { FC, useMemo } from "react";
import "./SlothFwdIntroSceneOne.scss";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import getClasses from "utils/getClasses";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { getTransitionClass } from "utils/getTransitionClass";
import surprisedBrady from "assets/img/sloth/elements/surprised-brady.svg";
import sadBrady from "assets/img/sloth/elements/sad-brady.svg";
import optimisticBrady from "assets/img/sloth/elements/optimistic-brady.svg";
import indignatedFelix from "assets/img/common/Detective/indignation.svg";
import lookingFelix from "assets/img/common/Detective/search-2.svg";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";
/**
 * SlothFwdIntroSceneOne Component
 *
 * This component represents a first chapter scene of forward flow.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @param {boolean} page - Indicates number of the scene inside component.
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const slothFwdIntroSceneOneData: SceneDataT = [
  {
    title: "Brady",
    text: [
      "Dove sono finiti tutti gli alberi? Chi li ha portati via? Detective Felix, c'è un nuovo caso per te!",
    ],
    audio: {
      brady: "sloth/brady-01",
    },
    images: {
      brady: surprisedBrady,
    },
  },
  {
    title: "Detective Felix",
    text: [
      "I colpevoli che stiamo cercando stanno mettendo in pericolo gli animali e danneggiano l’ambiente!",
    ],
    audio: {
      detective: "sloth/detective-felix-01",
    },
    images: {
      brady: sadBrady,
      felix: indignatedFelix,
    },
  },
  {
    title: "Detective Felix",
    text: [
      "Dobbiamo cercare altri indizi, ma prima è il caso di aiutare Brady a piantare nuovi alberi. Da solo non potrà farcela!",
    ],
    audio: {
      detective: "sloth/detective-felix-02",
    },
    images: {
      brady: optimisticBrady,
      felix: lookingFelix,
    },
  },
];

export const SlothFwdIntroSceneOne: FC<Partial<ChapterSceneFC>> = ({
  out,
  page = 1,
  action,
}) => {
  const contents = slothFwdIntroSceneOneData;
  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });

  const renderSpeech = useMemo(
    () => (
      <SpeechAudio
        notVisible
        audioFiles={[
          page === 1 ? `${contents[0].audio?.brady}` : `${contents[page - 1].audio?.detective}`,
        ]}
        start={Boolean(page)}
        startDelay={page === 1 ? 1000 : 0}
        endDelay={1000}
        setFinished={() => setIsFinished(true)}
      />
    ),
    [contents, page, setIsFinished]
  );

  return (
    <div
      className={`sloth-chapter-one-container fade-in-image ${getClasses({
        "fade-out-image": out && page === 3,
      })}`}
    >
      <div className="content">
        {Array.from({ length: contents.length }).map((_, pageDiv) => {
          const {
            title,
            text,
            images: { brady, felix },
          } = contents[pageDiv];
          return (
            page === pageDiv + 1 && (
              <div
                key={`scene-page-${pageDiv + 1}`}
                className={`page-container ${getTransitionClass([page, pageDiv + 1])}`}
              >
                <img className="brady-figure" src={brady} alt="brady" />
                <div className={`balloon-container balloon-${pageDiv + 1}`}>
                  <SimpleCard title={title} text={text} />
                </div>
                {renderSpeech}
                {page !== 1 && <img className="felix-figure" src={felix} alt="felix" />}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};
