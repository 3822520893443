import { IUseGame } from "models/IUseGame";
import { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modeGameSelector, setCurrentSequence, setModeGame } from "store/slices/modeGameSlice";
import { setLevelGame, setScore } from "store/slices/currentSessionSlice";
import { EnumCurrentSessionAnswer, EnumCurrentSessionMode } from "models/EnumCurrentSession";
import { ModeGameEnum, TypeGameEnum } from "models/EnumModeGame";
import { useEffectAudio } from "./useEffectAudio";
/**
 * useParrotGame Custom Hook: Manages parrot game business logic.
 * @param {Object} options - Options for configuring the game.
 * @param {boolean} options.reverse - Indicates whether the game should be in reverse mode.
 * @returns {Object} - An object containing game state and functions.
 */
const useParrotGame = ({ reverse }: IUseGame) => {
  const [matrix, setMatrix] = useState<boolean[][]>();
  const [isFlashing, setIsFlashing] = useState(false);
  const [level, setLevel] = useState(2);
  const [currentStep, setCurrentStep] = useState(0);
  const [isSequenceShowing, setIsSequenceShowing] = useState(false);
  const [gameSequence, setGameSequence] = useState<number[][]>([]);
  const [userSequence, setUserSequence] = useState<number[][]>([]);
  // const [isWarning, setIsWarning] = useState(false);

  const minLevel = 2;
  const [decrementCounter, setDecrementCounter] = useState(0);
  const [incrementCounter, setIncrementCounter] = useState(0);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const { activePlayWin } = useEffectAudio();

  const dispatch = useDispatch();
  const modeGame = useSelector(modeGameSelector);

  const tutorialSequence = useCallback((reverse: boolean = false) => {
    const baseSequence = [
      [0, 0],
      [1, 1],
      [3, 2],
      [2, 1],
    ];
    return reverse ? baseSequence.reverse() : baseSequence;
  }, []);

  const resetGame = useCallback(() => {
    setMatrix(createMatrix());
    setIsSequenceShowing(false);
    setCurrentStep(0);
    setGameSequence([]);
    setUserSequence([]);
    setLevel(2);
    setDecrementCounter(0);
    setIncrementCounter(0);
  }, []);

  const createMatrix = (isFlashing: boolean = false, rows: number = 4, cols: number = 4) => {
    const newMatrix = new Array(rows).fill(null).map(() => new Array(cols).fill(false));
    setIsFlashing(isFlashing);
    return newMatrix;
  };

  const resetMatrix = useCallback((isFlashing: boolean = false) => {
    setMatrix(createMatrix(isFlashing));
  }, []);

  const turnOnSquare = useCallback((selectedRow: number, selectedCol: number) => {
    setMatrix(() => {
      const updatedMatrix = createMatrix();
      updatedMatrix[selectedRow][selectedCol] = true;
      return updatedMatrix;
    });
  }, []);

  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  const elementSelectionHandler = (row: number, col: number) => {
    if (isSequenceShowing || modeGame.type === TypeGameEnum.TRAINING_TUTORIAL) {
      return;
    }

    if (modeGame.type === TypeGameEnum.PLAYING_TUTORIAL) {
      const [rightRow, rightCol] = tutorialSequence(reverse)[currentStep];
      if (rightRow === row && rightCol === col) {
        setUserSequence((prevUserChoices) =>
          prevUserChoices ? [...prevUserChoices, [row, col]] : [[row, col]]
        );
      } else {
        return;
      }
    } else {
      if (userSequence.length !== gameSequence.length) {
        setIsFlashing(false);
        resetMatrix();
        turnOnSquare(row, col);
        userSequence.length < gameSequence.length &&
          setUserSequence((prevUserChoices) =>
            prevUserChoices ? [...prevUserChoices, [row, col]] : [[row, col]]
          );
        setTimeout(() => {
          resetMatrix();
        }, 500);
        setCurrentStep((prev) => prev + 1);
        startTimer();
      }
    }
  };

  const showSequence = useCallback(
    (isTutorial: boolean = true) => {
      if (isSequenceShowing) return;
      let playbackIndex = 0;
      const sequence = isTutorial ? tutorialSequence() : gameSequence;
      setIsSequenceShowing(true);
      const playSequence = () => {
        if (playbackIndex < sequence.length) {
          const [currentRow, currentCol] = sequence[playbackIndex];
          turnOnSquare(currentRow, currentCol);
          playbackIndex++;
          setTimeout(playSequence, 1000);
        } else {
          resetMatrix();
          setIsSequenceShowing(false);
          dispatch(setCurrentSequence(gameSequence));
          setUserSequence([]);
          isTutorial &&
            setTimeout(() => {
              dispatch(
                setModeGame({
                  type: TypeGameEnum.PLAYING_TUTORIAL,
                  mode: modeGame.mode,
                })
              );
            }, 800);
        }
      };
      playSequence();
    },
    //eslint-disable-next-line
    [tutorialSequence, gameSequence, turnOnSquare, resetMatrix, dispatch, modeGame.mode]
  );

  const checkUserChoice = (rightAnswer: number[], userChoice: number[]) => {
    return rightAnswer.every((coordinate, index) => coordinate === userChoice[index]);
  };

  const gameTutorial = useCallback(() => {
    const demoSequence = tutorialSequence(reverse);
    const [demoCurrentRow, demoCurrentCol] = demoSequence[currentStep];
    turnOnSquare(demoCurrentRow, demoCurrentCol);
    if (
      currentStep < demoSequence.length &&
      userSequence.length === currentStep + 1 &&
      checkUserChoice(demoSequence[currentStep], userSequence[currentStep])
    ) {
      if (demoSequence.length === userSequence.length) {
        setUserSequence([]);
        setCurrentStep(0);
        resetMatrix();
        dispatch(
          setModeGame({
            type: TypeGameEnum.START_MODAL_GAME,
            mode: modeGame.mode,
          })
        );
        return;
      }
      setCurrentStep(currentStep + 1);
    }
  }, [
    tutorialSequence,
    reverse,
    currentStep,
    turnOnSquare,
    userSequence,
    resetMatrix,
    dispatch,
    modeGame.mode,
  ]);

  const getRandomSequence = useCallback(() => {
    const randomCoordinates = Array.from({ length: level }, () => [
      Math.floor(Math.random() * 4),
      Math.floor(Math.random() * 4),
    ]);
    randomCoordinates.forEach((item, index, arr) => {
      let [currX, currY] = item;
      if (index > 0) {
        const [prevX, prevY] = arr[index - 1];
        if (currX === prevX && currY === prevY) {
          arr[index] = [
            currX === 3 ? currX - 1 : currX + 1,
            currY === 3 ? currY - 1 : currY + 1,
          ];
        }
      }
      return item;
    });
    setGameSequence(randomCoordinates);
  }, [level]);

  const arrayEquals = (a: number[][], b: number[][]) => {
    return (
      a.length === b.length &&
      a.every(
        (row, rowIndex) =>
          row.length === b[rowIndex].length &&
          row.every((item, index) => item === b[rowIndex][index])
      )
    );
  };

  const playGameRound = useCallback(() => {
    setCurrentStep(0);
    getRandomSequence();
  }, [getRandomSequence]);

  const checkWinCondition = useCallback(() => {
    if (
      userSequence &&
      userSequence.length > 0 &&
      gameSequence.length === userSequence.length &&
      modeGame.type === TypeGameEnum.PLAY_GAME
    ) {
      const dispatchFn = (
        prev: number,
        answerType: EnumCurrentSessionAnswer,
        incrementLevel?: boolean
      ) => {
        (prev + 1) % 2 === 0 &&
          prev !== 0 &&
          setLevel((prev) => {
            const level =
              incrementLevel ? prev + 1
              : minLevel > prev - 1 ? minLevel
              : prev - 1;

            dispatch(
              setLevelGame({
                level,
                mode:
                  reverse ?
                    EnumCurrentSessionMode.MODE_REVERSE
                  : EnumCurrentSessionMode.MODE_FORWARD,
              })
            );
            return level;
          });
        dispatch(
          setScore({
            answer: answerType,
            mode:
              reverse ?
                EnumCurrentSessionMode.MODE_REVERSE
              : EnumCurrentSessionMode.MODE_FORWARD,
          })
        );
        return prev + 1;
      };
      if (arrayEquals(gameSequence, reverse ? userSequence.reverse() : userSequence)) {
        activePlayWin();
        resetMatrix();
        setDecrementCounter(0);
        setIncrementCounter((prev) =>
          dispatchFn(prev, EnumCurrentSessionAnswer.ANSWER_RIGHT, true)
        );
        setUserSequence([]);
      } else {
        resetMatrix();
        setIncrementCounter(0);
        setDecrementCounter((prev) => dispatchFn(prev, EnumCurrentSessionAnswer.ANSWER_WRONG));
        setUserSequence([]);
      }
    }
  }, [
    userSequence,
    gameSequence,
    modeGame.type,
    reverse,
    resetMatrix,
    dispatch,
    activePlayWin,
  ]);

  const startTimer = useCallback(() => {
    resetTimer();
    if (
      modeGame.type === TypeGameEnum.PLAY_GAME &&
      gameSequence[currentStep] &&
      !userSequence[currentStep]
    ) {
      timerRef.current = setTimeout(() => {
        // setIsWarning(true);
        resetMatrix(true);
      }, 5000);
    }

    // setIsWarning(false);
  }, [userSequence, gameSequence, resetMatrix, currentStep, modeGame.type]);

  useEffect(() => {
    if (modeGame.type === TypeGameEnum.PLAY_GAME || incrementCounter || decrementCounter) {
      playGameRound();
    }
  }, [decrementCounter, incrementCounter, modeGame.type, playGameRound]);

  useEffect(() => {
    setTimeout(() => {
      showSequence(false);
    }, 1000);
  }, [showSequence]);

  useEffect(() => {
    !isSequenceShowing && startTimer();
  }, [isSequenceShowing, startTimer]);

  useEffect(() => {
    if (userSequence.length === gameSequence.length) {
      setTimeout(() => {
        checkWinCondition();
      }, 1000);
    }
    modeGame.type === TypeGameEnum.PLAYING_TUTORIAL && gameTutorial();
  }, [userSequence, gameTutorial, checkWinCondition, modeGame.type, gameSequence.length]);

  // useEffect(() => {
  //   if (isWarning) {
  //     const flashWarning = () => {
  //       setMatrix(createMatrix(true));
  //       setTimeout(() => {
  //         resetMatrix();
  //       }, 1000);
  //     };

  //     flashWarning();
  //     setTimeout(() => {
  //       flashWarning();
  //     }, 1500);

  //     setTimeout(() => {
  //       setIsWarning(false);
  //     }, 1200);
  //   }
  // }, [isWarning, resetMatrix]);

  useEffect(() => {
    modeGame.mode === ModeGameEnum.REVERSE_GAME && resetGame();
  }, [modeGame.mode, resetGame]);

  return {
    matrix,
    modeGame,
    currentStep,
    elementSelectionHandler,
    showSequence,
    isFlashing,
    isSequenceShowing,
    // isWarning,
  };
};

export default useParrotGame;
