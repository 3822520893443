import { EnumGame, EnumPath, EnumPlayMode, EnumSceneFlow } from "models/EnumPath";
import { SceneEnum } from "models/EnumScene";
import { useParams } from "react-router-dom";

export const useGamePath = (game?: EnumGame) => {
  const gameSceneBasePath = `/${EnumPath.GAMES}/${game}/${EnumPath.SCENE}`;
  const gamePlayingBasePath = `/${EnumPath.GAMES}/${game}`;
  const { mode, sceneFlow, id } = useParams<{
    mode: EnumPlayMode;
    sceneFlow: EnumSceneFlow;
    id: SceneEnum;
  }>();
  const wichSceneFlowIs = (flowToCheck: EnumSceneFlow) => flowToCheck === sceneFlow;
  const wichGameModeIs = (modeToCheck: EnumPlayMode) => modeToCheck === mode;

  const gamePathScene = {
    firstPlay: `${gameSceneBasePath}/${EnumSceneFlow.FORWARD}/${SceneEnum.FORWARD_STARTED}`,
    reversPlay: `${gameSceneBasePath}/${EnumSceneFlow.REVERSE}/${SceneEnum.REVERSE_STARTED}`,
    firstEnding: `${gameSceneBasePath}/${EnumSceneFlow.END}/${SceneEnum.END_ONE}`,
    spinOff: `${gameSceneBasePath}/${EnumSceneFlow.SPINOFF}/${SceneEnum.SPINOFF_FIRST_ONE}`,
    spinOffHalf: `${gameSceneBasePath}/${EnumSceneFlow.SPINOFF}/${SceneEnum.SPINOFF_FIRST_TWO}`,
    spinOffEnding: `${gameSceneBasePath}/${EnumSceneFlow.SPINOFF}/${SceneEnum.SPINOFF_FIRST_THREE}`,
  };
  const gamePathPlaying = {
    normal: `${gamePlayingBasePath}/${EnumPath.PLAY_NORMAL}`,
    revers: `${gamePlayingBasePath}/${EnumPath.PLAY_REVERSE}`,
    tutorial: `${gamePlayingBasePath}/${EnumPath.PLAY_TUTORIAL}`,
    tutorialRevers: `${gamePlayingBasePath}/${EnumPath.PLAY_TUTORIAL_REVERSE}`,
  };

  return {
    currentGameMode: mode,
    currrentSceneFlow: sceneFlow,
    currentSceneId: id,
    wichSceneFlowIs,
    gamePathScene,
    gamePathPlaying,
    wichGameModeIs,
  };
};
