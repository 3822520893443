import React, { FC, useMemo } from "react";
import "./ParrotRevIntroSceneOne.scss";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";

import getClasses from "utils/getClasses";
import openChest from "assets/img/parrot/elements/treasure.svg";
import chestAlone from "assets/img/common/elements/alone-reverse.svg";
import felixAlone from "assets/img/common/elements/alone.svg";
import pointingFelix from "assets/img/common/Detective/indication-zoom.svg";
import map from "assets/img/parrot/elements/map.svg";
import pinnedMap from "assets/img/parrot/elements/map-with-pin.svg";
import { getTransitionClass } from "utils/getTransitionClass";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";

/**
 * ParrotRevIntroSceneOne Component
 *
 * This component represents a chapter scene with multiple images.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const parrotRevIntroSceneOneData: SceneDataT = [
  {
    title: "",
    text: [],
    images: {},
  },
  {
    title: "Detective Felix",
    text: [
      "Chiunque abbia perso questa mappa probabilmente ha a che fare con la sparizione dei pappagalli.",
      "Seguiamo questo percorso. Magari ci condurrà a loro!",
    ],
    audio: {
      felix: "parrot/detective-felix-04",
    },
    images: {
      felix: pointingFelix,
    },
  },
  {
    title: "",
    text: [],
    images: {},
  },
];

export const ParrotRevIntroSceneOne: FC<Partial<ChapterSceneFC>> = ({
  out,
  page = 1,
  action,
}) => {
  const mapAnimationClasses = (page: number) => {
    return Array.from({ length: page }).reduce((classes, _, index) => {
      return (classes += `map-${index + 1} `);
    }, "");
  };

  const contents = parrotRevIntroSceneOneData;
  const {
    title,
    text,
    images: { felix },
  } = contents[1];
  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });

  const renderAudio = useMemo(
    () => (
      <SpeechAudio
        notVisible={true}
        start={Boolean(page)}
        audioFiles={[page === 2 ? contents[page - 1].audio!.felix : ""]}
        setFinished={() => setIsFinished(true)}
      />
    ),
    [contents, page, setIsFinished]
  );

  return (
    <div className={`reverse-parrot-scene-one ${getClasses({ "fade-out-image": out })}`}>
      <div className="content">
        <img
          className={`map-image ${mapAnimationClasses(page)}`}
          src={page !== 3 ? map : pinnedMap}
          alt="Eufania map on the background"
        />
        {page === 2 && <div className={`background-overlay over-${page}`}></div>}
        {Array.from({ length: 2 }).map(
          (_, pageDiv) =>
            page === pageDiv + 1 && (
              <div
                key={`page ${pageDiv + 1}`}
                className={`page-content ${getTransitionClass([page, pageDiv + 1])}`}
              >
                {pageDiv === 0 && (
                  <>
                    <img
                      className="chest-alone"
                      src={chestAlone}
                      alt="Alone behind the chest"
                    />
                    <img className="chest" src={openChest} alt="An opened chest" />
                  </>
                )}
                {pageDiv === 1 && (
                  <>
                    <img className="felix-alone" src={felixAlone} alt="Alone behind Felix" />
                    <div className="balloon-container">
                      <SimpleCard title={title} text={text} />
                    </div>

                    <img className="felix-figure" src={felix} alt="Felix" />
                  </>
                )}
              </div>
            )
        )}
      </div>
      {renderAudio}
    </div>
  );
};
