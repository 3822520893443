import handIcon from "assets/img/icons/pointer-sloth-game.svg";
import { Modal } from "components/Modal/Modal";
import useGetCurrentUser from "hook/useGetCurrentUser";
import { ModeGameEnum, TypeGameEnum } from "models/EnumModeGame";
import { EnumGame, EnumPath, EnumPlayMode } from "models/EnumPath";
import { SceneEnum } from "models/EnumScene";
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  modeGameSelector,
  setCurrentScene,
  setGame,
  setModeGame,
} from "store/slices/modeGameSlice";
import "./SlothGame.scss";

import detectiveOk from "assets/img/common/Detective/detective_sloth_game_ok.svg";
import detectiveSpeak from "assets/img/common/Detective/detective_sloth_game_speak.svg";
import { DemoSlothGame } from "components/DemoGame/DemoSlothGame/DemoSlothGame";
import { GenericButton } from "components/GenericButton/GenericButton";
import { RoundButton } from "components/RoundButton/RoundButton";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { Square } from "components/Square/Square";
import useEditSessionGame from "hook/useEditSessionGame";
import { useEffectAudio } from "hook/useEffectAudio";
import { useSlothGame } from "hook/useSlothGame";
import { SlothScore } from "models/Session";
import { useNavigate } from "react-router-dom";
import { setSlothScore } from "store/slices/currentSessionSlice";
import getClasses from "utils/getClasses";
import { SpeechAudio } from "components/SpeechAudio/SpeechAudio";
import { useGamePath } from "hook/useGamePath";

export const nBackTutorialInfo: Record<number, string> = {
  1: "alla precedente",
  2: "a quella mostrata due volte prima",
  3: "a quella mostrata tre volte prima",
};

export const SlothGame = React.memo(() => {
  const { updateCurrentSession } = useEditSessionGame();
  const dispatch = useDispatch();
  const modeGame = useSelector(modeGameSelector);
  const { currentUser } = useGetCurrentUser();
  const { currentScene } = useSelector(modeGameSelector);
  const [isModalDemoOpen, setIsModalDemoOpen] = useState(true);
  const [isModalGameOpen, setIsModalGameOpen] = useState(false);
  const [isModalEndGameOpen, setIsModalEndGameOpen] = useState(false);
  const [isStopSpeech, setIsStopSpeech] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const { skippedCard, sequence, nBack, setScore, checkUserChoiceAndScore } = useSlothGame();
  const [tutorialStep, setTutorialStep] = useState<number>(0);
  const [deckAnimationStep, setDeckAnimationStep] = useState<number>(0);
  const [showHand, setShowHand] = useState(false);
  const [tooltip, setTooltip] = useState({ isShow: false, text: "" });
  const deckRef = useRef<HTMLDivElement | null>(null);
  const balloonRef = useRef<HTMLDivElement | null>(null);
  const targetCoupleFirstCard = 3;
  const targetCard = targetCoupleFirstCard + nBack;
  const navigate = useNavigate();
  const { wichGameModeIs, gamePathScene } = useGamePath(EnumGame.SLOTH_GAME);
  const { hasPlayedGame } = useGetCurrentUser();
  const { toggleCurrentMusic } = useEffectAudio();
  const [isCorrectCardClicked, setIsCorrectCardClicked] = useState(false);

  const isNormalPlay = wichGameModeIs(EnumPlayMode.PLAY_NORMAL);
  const isReversePlay = wichGameModeIs(EnumPlayMode.PLAY_REVERSE);

  const tutorialInstructions = useMemo(() => {
    return {
      title: "Detective Felix",
      text: [
        "Vedrai delle immagini comparire al centro dello schermo.",
        "Osservale con attenzione, alcune si ripeteranno più volte.",
        `Tocca il riquadro ogni volta che l’immagine che vedi`,
        "Adesso che hai capito come funziona il gioco, facciamo una prova!",
      ],
    };
  }, []);

  const simpleCardText = useMemo(() => {
    if (tutorialInstructions.text) {
      switch (tutorialStep) {
        case 0:
        case 1:
          return tutorialInstructions.text[0];
        case targetCard:
          return (
            <>
              {tutorialInstructions.text[2]}{" "}
              <strong className="tesi-simple-box-bold">{`è identica ${nBackTutorialInfo[nBack]}`}</strong>
            </>
          );
        case targetCard + 2:
          return tutorialInstructions.text[3];
        default:
          return tutorialInstructions.text[1];
      }
    }
  }, [tutorialInstructions, tutorialStep, targetCard, nBack]);

  const audioCards = useMemo(() => {
    switch (tutorialStep) {
      case 0:
      case 1:
        return "sloth/detective-felix-06";
      case targetCard:
        return "sloth/detective-felix-touch-same";
      case targetCard + 2:
        return "general/detective-felice-make-a-try";
      default:
        return "sloth/detective-felix-07";
    }
  }, [tutorialStep, targetCard]);

  const textModal = useMemo(() => {
    if (isModalDemoOpen) {
      //TODO: fix condition based on nback variation
      return (
        currentScene.includes(SceneEnum.SPINOFF_SCENE) ?
          "Prima di procedere, vuoi fare una prova?"
        : nBack !== 1 ?
          !modeGame.isFirstGame ?
            `Ricorda, dovrai toccare il riquadro ogni volta che l’immagine che vedi è identica ${nBackTutorialInfo[nBack]}`
          : "Ora presta molta attenzione perché le regole del gioco sono un po’ cambiate!"
        : "Prima di procedere, ti mostrerò come funziona il gioco"
      );
    } else if (isModalEndGameOpen) {
      return "Ben Fatto. Ci siamo quasi!";
    } else {
      return currentScene.includes(SceneEnum.SPINOFF_SCENE) ? "Giochiamo!" : (
          `Ok, sei pront${currentUser?.gender === "MALE" ? "o" : "a"}. Giochiamo!`
        );
    }
  }, [
    currentScene,
    currentUser?.gender,
    isModalDemoOpen,
    isModalEndGameOpen,
    modeGame.isFirstGame,
    nBack,
  ]);

  const audioModal =
    currentScene.includes(SceneEnum.SPINOFF_SCENE) ? "Giochiamo!"
    : isModalDemoOpen ? "sloth/detective-felix-05"
    : `general/detective-felix-ok-u-ready-${
        currentUser && currentUser.gender === "MALE" ? "M" : "F"
      }`;

  const closeModal = useCallback(
    (
      type:
        | TypeGameEnum.TRAINING_TUTORIAL
        | TypeGameEnum.PLAYING_TUTORIAL
        | TypeGameEnum.SPEECH_GAME
        | TypeGameEnum.PLAY_GAME,
      repeat?: boolean
    ) => {
      // reset tutorial score and store update
      const initialScore: SlothScore = { rightCouples: 0, wrongCouples: 0, skippedCouples: 0 };
      setScore(initialScore);
      dispatch(
        setSlothScore({
          gamePart: `${isReversePlay ? "secondPart" : "firstPart"}`,
          score: initialScore,
        })
      );
      setIsFadingOut(true);
      if (repeat) {
        setIsModalDemoOpen(true);
        setIsModalGameOpen(false);
      }
      setTimeout(() => {
        const mode = modeGame.mode;
        type === TypeGameEnum.TRAINING_TUTORIAL && setIsModalDemoOpen(false);
        type === TypeGameEnum.PLAY_GAME && setIsModalGameOpen(false);
        dispatch(
          setModeGame({
            type: repeat ? TypeGameEnum.PLAY_GAME : type,
            mode,
          })
        );
        setIsFadingOut(false);
      }, 750);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, modeGame.mode, setScore]
  );

  useEffect(() => {
    dispatch(setGame(EnumPath.SLOTH_GAME));
  }, [dispatch]);

  useEffect(() => {
    toggleCurrentMusic("music", "demo1");
  }, [toggleCurrentMusic]);

  const animateCard = useCallback(
    (cardIndex: number) => {
      if (cardIndex < deckRef.current?.childNodes.length!) {
        const currentCard = deckRef.current!.children[cardIndex];
        const targetCard = sequence[cardIndex] === sequence[cardIndex - nBack];

        const handleCardClick = () => {
          checkUserChoiceAndScore(cardIndex);

          if (targetCard) {
            setIsCorrectCardClicked(true);
            setTooltip({ isShow: true, text: "Grande!" });
            currentCard.classList.add("skipped");

            currentCard.addEventListener("animationend", (e) => {
              const event = e as AnimationEvent;
              if (event.animationName === "skipped") {
                resetCard();
              }
            });
          }
          if (!targetCard) {
            setIsCorrectCardClicked(false);
            setTooltip({ isShow: true, text: "Riprova" });
            currentCard.classList.add("skipped");
          }
        };

        currentCard.addEventListener("click", handleCardClick);

        const swipeOutCard = () => {
          setTimeout(() => {
            // Se la carta è stata cliccata correttamente, saltiamo completamente questa parte
            if (targetCard && !isCorrectCardClicked) {
              // Mostriamo "Presta più attenzione" solo se non c'è stato un click corretto
              if (!currentCard.classList.contains("skipped")) {
                currentCard.children[0].classList.add("skipped-with-image");
                setTooltip({ isShow: true, text: "Presta più attenzione" });
                currentCard.classList.add("skipped");
                skippedCard();
              }
            }

            currentCard.classList.add("swipe-out");
            currentCard.addEventListener("animationend", (e) => {
              const event = e as AnimationEvent;
              if (event.animationName === "swipe-out") {
                resetCard();
              }
            });
          }, 500);
        };

        const swipeInCard = () => {
          currentCard.classList.add("swipe-in");
          currentCard.addEventListener("animationend", (e) => {
            const event = e as AnimationEvent;
            if (event.animationName === "swipe-in") {
              swipeOutCard();
            }
          });
        };

        const resetCard = () => {
          setTooltip({ isShow: false, text: "" });
          currentCard.classList.remove("swipe-in", "swipe-out", "skipped");
          currentCard.children[0].classList.remove("skipped-with-image", "selected-with-image");
          setDeckAnimationStep((prev) => prev + 1);
          setIsCorrectCardClicked(false); // Reset correct card click
        };

        swipeInCard();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nBack, sequence, skippedCard, isCorrectCardClicked]
  );

  const tutorialAnimation = useCallback(
    (step: number) => {
      const currentTutorialCard = deckRef.current?.children[step];
      const cardToClick = deckRef.current?.children[targetCard].children[0];
      const balloon = balloonRef.current!;
      const animateBalloon = () => {
        balloon.classList.add("move-down");
        balloon.addEventListener("animationend", moveBalloonUp);
      };
      const moveBalloonUp = () => {
        balloon.removeEventListener("animationend", moveBalloonUp);
        balloon.classList.add("move-up");
        balloon.addEventListener("animationend", resetBalloon);
      };
      const resetBalloon = () => {
        balloon.removeEventListener("animationend", resetBalloon);
        balloon.classList.remove("move-down", "move-up");
      };
      const swipeInCard = () => {
        if (step !== targetCoupleFirstCard && step !== targetCard) {
          currentTutorialCard?.classList.add("no-target");
        }
        currentTutorialCard?.classList.add("tutorial-card", "swipe-in");
        currentTutorialCard?.addEventListener("animationend", swipeOutCard);
      };
      const swipeOutCard = () => {
        currentTutorialCard?.removeEventListener("animationend", swipeOutCard);
        setTimeout(() => {
          if (step < targetCard) {
            currentTutorialCard?.classList.add("swipe-out");
            currentTutorialCard?.addEventListener("animationend", resetCard);
          }

          if (step === targetCard) {
            currentTutorialCard?.classList.add("zoom-in");
            setTimeout(nextStep, 3000);
          }
        }, 500);
      };
      const resetCard = () => {
        currentTutorialCard?.removeEventListener("animationend", resetCard);
        currentTutorialCard?.classList.remove(
          "tutorial-card",
          "no-target",
          "swipe-in",
          "swipe-out"
        );
        // set new state to trigger animateCard again
        setTimeout(nextStep, 500);
      };
      const nextStep = () => {
        setTutorialStep((prev) => prev + 1);
      };

      switch (step) {
        case 0:
          setIsStopSpeech(false);
          swipeInCard();
          break;
        case 2:
          setIsStopSpeech(false);
          animateBalloon();
          swipeInCard();
          break;
        case targetCard:
          setIsStopSpeech(false);
          animateBalloon();
          swipeInCard();
          setTimeout(() => setShowHand(true), 2000);
          break;
        case targetCard + 1:
          balloon.classList.add("move-down");
          cardToClick?.classList.add("selected-with-image");
          setTimeout(() => nextStep(), 2000);
          break;
        case targetCard + 2:
          balloon.classList.add("move-up");
          break;
        default:
          swipeInCard();
          break;
      }
    },
    [targetCard]
  );

  const animateTutorial = useCallback(() => {
    tutorialAnimation(tutorialStep); // Start animation with tutorial step counter
  }, [tutorialAnimation, tutorialStep]);

  const animateDeck = useCallback(() => {
    animateCard(deckAnimationStep); // Start animation with game step counter
  }, [animateCard, deckAnimationStep]);

  // useEffect to close modal & activate the game animation
  useEffect(() => {
    if (
      modeGame.type !== TypeGameEnum.SPEECH_GAME_TUTORIAL &&
      [TypeGameEnum.PLAY_GAME, TypeGameEnum.PLAYING_TUTORIAL].includes(modeGame.type)
    ) {
      animateDeck();
    }
    modeGame.type === TypeGameEnum.START_MODAL_GAME && setIsModalGameOpen(true);
    modeGame.type === TypeGameEnum.END_MODAL_GAME && setIsModalEndGameOpen(true);
  }, [animateDeck, modeGame.type]);

  const navigateAfterGame = useCallback(
    (timeout = 2000) => {
      setTimeout(async () => {
        if (isNormalPlay) {
          await updateCurrentSession({ isReverseGame: false, completed: false });
          dispatch(
            setModeGame({
              type: TypeGameEnum.START_MODAL_DEMO,
              mode: ModeGameEnum.FORWARD_GAME,
            })
          );
        } else {
          await updateCurrentSession({ isReverseGame: true, completed: true });
          dispatch(
            setModeGame({
              type: TypeGameEnum.START_MODAL_DEMO,
              mode: ModeGameEnum.REVERSE_GAME,
            })
          );
        }

        dispatch(
          setCurrentScene(
            modeGame.mode === ModeGameEnum.FORWARD_GAME ?
              modeGame.currentScene.includes(SceneEnum.SPINOFF_SCENE) ?
                modeGame.currentScene.includes(SceneEnum.SPINOFF_FIRST) ?
                  SceneEnum.SPINOFF_FIRST_TWO
                : SceneEnum.SPINOFF_SECOND_TWO
              : SceneEnum.REVERSE_STARTED
            : modeGame.currentScene.includes(SceneEnum.SPINOFF_SCENE) ?
              modeGame.currentScene.includes(SceneEnum.SPINOFF_FIRST) ?
                SceneEnum.SPINOFF_FIRST_THREE
              : SceneEnum.SPINOFF_SECOND_THREE
            : SceneEnum.END_ONE
          )
        );

        if (isNormalPlay) {
          navigate(
            hasPlayedGame(EnumGame.SLOTH_GAME) ?
              gamePathScene.spinOffHalf
            : gamePathScene.reversPlay
          );
        }

        if (isReversePlay) {
          navigate(
            hasPlayedGame(EnumGame.SLOTH_GAME) ?
              gamePathScene.spinOffEnding
            : gamePathScene.firstEnding
          );
        }
      }, timeout);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      hasPlayedGame,
      modeGame.currentScene,
      modeGame.mode,
      modeGame.type,
      navigate,
      updateCurrentSession,
    ]
  );

  const dispatchAfterGame = useCallback(() => {
    if (
      modeGame.type === TypeGameEnum.PLAY_GAME &&
      modeGame.mode !== ModeGameEnum.REVERSE_GAME
    ) {
      setIsModalEndGameOpen(true);
    }
    dispatch(
      setModeGame({
        mode: modeGame.mode,
        type:
          modeGame.type === TypeGameEnum.PLAY_GAME ?
            TypeGameEnum.END_MODAL_GAME
          : TypeGameEnum.START_MODAL_GAME,
      })
    );
    if (
      modeGame.mode === ModeGameEnum.REVERSE_GAME &&
      modeGame.type === TypeGameEnum.PLAY_GAME
    ) {
      navigateAfterGame(0);
    }
  }, [dispatch, modeGame.mode, modeGame.type, navigateAfterGame]);

  useEffect(() => {
    if (isModalEndGameOpen) navigateAfterGame();

    // linter is wrong on this deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalEndGameOpen]);

  const resetTutorial = () => {
    // reset last card classes
    deckRef.current!.children[targetCard].classList.remove(
      "tutorial-card",
      "swipe-in",
      "zoom-in"
    );
    //reset balloon
    balloonRef.current?.classList.remove("move-down", "move-up");
    //unclick card
    deckRef.current?.children[targetCard].children[0].classList.remove("selected-with-image");
    // reset hand
    setShowHand(false);
    // reset counter
    setTutorialStep(0);
    // reset speech
    setIsStopSpeech(false);
  };

  useEffect(() => {
    // end game dispatch after deck last card

    if (sequence.length > 0 && deckAnimationStep === sequence.length - 1) {
      dispatchAfterGame();
      setDeckAnimationStep(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deckAnimationStep, sequence.length]);

  // useEffect to activate the tutorial animation
  useEffect(() => {
    if (modeGame.type === TypeGameEnum.SPEECH_GAME_TUTORIAL) {
      animateTutorial();
    }
  }, [animateTutorial, modeGame.type]);

  const renderSpeech = useMemo(
    () =>
      (
        modeGame.type !== TypeGameEnum.TRAINING_TUTORIAL &&
        modeGame.type === TypeGameEnum.SPEECH_GAME_TUTORIAL &&
        tutorialStep &&
        !isStopSpeech
      ) ?
        <SpeechAudio
          notVisible
          audioFiles={[audioCards ?? ""]}
          start={Boolean(!isStopSpeech)}
          startDelay={500}
          setFinished={() => setIsStopSpeech(true)}
        />
      : null,
    [audioCards, isStopSpeech, modeGame.type, setIsStopSpeech, tutorialStep]
  );

  const renderAudioModal = useMemo(
    () => (
      <SpeechAudio
        notVisible
        audioFiles={[`${audioModal}`]}
        start={isModalDemoOpen || isModalGameOpen}
        setFinished={() => setIsStopSpeech(true)}
      />
    ),
    [audioModal, isModalDemoOpen, isModalGameOpen, setIsStopSpeech]
  );

  const clickableCondition =
    modeGame.type === TypeGameEnum.PLAY_GAME ||
    modeGame.type === TypeGameEnum.PLAYING_TUTORIAL ||
    !skippedCard;

  return (
    <>
      <Modal
        isOpen={isModalDemoOpen || isModalGameOpen || isModalEndGameOpen}
        onClose={() => closeModal(TypeGameEnum.TRAINING_TUTORIAL)}
      >
        <div className={`${isFadingOut ? "fade-out-image" : "fade-in-image"}`}>
          <DemoSlothGame
            data-cy="demo-modal"
            type="demo"
            action={() =>
              closeModal(
                isModalGameOpen ? TypeGameEnum.PLAY_GAME : TypeGameEnum.TRAINING_TUTORIAL
              )
            }
            startTutorial={() => closeModal(TypeGameEnum.TRAINING_TUTORIAL)}
            reverse={modeGame.mode === ModeGameEnum.REVERSE_GAME}
            title={textModal}
          />
        </div>
      </Modal>

      {!isModalDemoOpen && !isModalGameOpen && !isModalEndGameOpen && (
        <div className={`sloth-game`}>
          <div ref={deckRef} className="sloth-game-deck">
            {sequence?.map((col, i) => (
              <Fragment key={`fragment-${col}-${i}`}>
                <div className="sloth-game-card" key={`${col}-${i}`}>
                  <Square
                    key={`square-${col}-${i}`}
                    element={col}
                    withIMG
                    clickable={clickableCondition}
                  />
                  {tooltip.isShow && (
                    <div className="sloth-game-tooltip">
                      <SimpleCard title={tooltip.text} customTooltip />
                    </div>
                  )}
                </div>
                {i === targetCard &&
                  showHand &&
                  modeGame.type === TypeGameEnum.SPEECH_GAME_TUTORIAL && (
                    <img
                      key={`hand-${col}-${i}`}
                      className="tutorial-hand fade-in-image"
                      src={handIcon}
                      alt="hand"
                    />
                  )}
              </Fragment>
            ))}
          </div>
          {(modeGame.type === TypeGameEnum.TRAINING_TUTORIAL ||
            modeGame.type === TypeGameEnum.SPEECH_GAME_TUTORIAL) && (
            <>
              {tutorialStep === targetCard + 2 && (
                <>
                  <div className="tutorial-end-overlay fade-in-image" />
                  <div className="tutorial-replay-button">
                    <RoundButton action={resetTutorial} type="tutorial-replay" />
                  </div>
                  <div className="tutorial-end-button fade-in-image">
                    <GenericButton
                      type="button"
                      text="FAI UNA PROVA"
                      color="theme-purple"
                      action={() => {
                        closeModal(TypeGameEnum.PLAYING_TUTORIAL);
                        setTimeout(resetTutorial, 750); // wait transition before reset
                      }}
                      size="large"
                    />
                  </div>
                </>
              )}
              <div
                className={`detective-wrapper ${getClasses({
                  "fade-in-image": tutorialStep === 0 || tutorialStep === targetCard + 1,
                })}`}
              >
                <img
                  className="detective-figure"
                  src={tutorialStep < targetCard + 1 ? detectiveSpeak : detectiveOk}
                  alt="detective"
                />
                <div ref={balloonRef} className="balloon-container">
                  <SimpleCard title={tutorialInstructions.title} text={[simpleCardText!]} />
                  {[1, 2, targetCard, targetCard + 2].includes(tutorialStep) && renderSpeech}
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {renderAudioModal}
    </>
  );
});
