import detective from "assets/img/common/Detective/detective-try-alone.svg";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { SpeechSynthesis } from "components/SpeechSynthesis/SpeechSynthesis";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import { SceneEnum } from "models/EnumScene";
import { FC } from "react";
import { useSelector } from "react-redux";
import { modeGameSelector } from "store/slices/modeGameSlice";
import getClasses from "utils/getClasses";
import "./ParrotSpinoffSceneTwo.scss";

/**
 * ParrotSpinoffSceneTwo Component
 * @param {boolean} out - Indicates whether to fade out the component.
 * @param {number} page - Indicates number of the scene inside component
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const spinoffData: SceneDataT = [
  {
    title: "",
    text: [],
    images: {},
  },
  {
    title: "Detective Felix",
    text: ["Ci siamo quasi! Adesso ripeti la sequenza nell’ordine inverso."],
    images: {
      felix: detective,
    },
  },
];

export const ParrotSpinoffSceneTwo: FC<Partial<ChapterSceneFC>> = ({
  out,
  page = 1,
  action,
}) => {
  const { currentScene } = useSelector(modeGameSelector);
  const {
    title,
    text,
    images: { felix },
  } = spinoffData[1];

  const contents = spinoffData;
  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });

  return (
    <div
      className={`container-spinoff ${
        currentScene?.includes(SceneEnum.SPINOFF_FIRST_TWO) ? "bg-first" : "bg-second"
      }-two fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      {page === 2 && (
        <>
          {/* TODO: to be repleced with audio */}
          <SpeechSynthesis
            notVisible
            texts={[text[0]]}
            setFinished={() => setIsFinished(true)}
          />
          <div className="container-gradient fade-in-image" />
          <div className="container-comic fade-in-image">
            <SimpleCard title={title} text={text} />
          </div>
          <img className="img-detective fade-in-image" src={felix} alt="detective" />
        </>
      )}
    </div>
  );
};
